import React from 'react'
import './index.css'

type Props = {
  parent: number
  level: number
  cols: number
  tinyTitle: string
  title: string
  color: string
}

const DotText = (data: Props) => {
  return (
    <div
      className={`shape-col-${data.cols} shape-text-dotText level-${data.parent}-${data.level}`}
    >
      {data.color ? <span className={` dot dot-${data.color}`}></span> : null}
      {data.title ? (
        <div className="shape-text-dotText-title"> {data.title} </div>
      ) : null}
    </div>
  )
}

export default DotText
