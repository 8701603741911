import React, { PureComponent } from 'react'
import MetaTags from 'react-meta-tags'

import { connect } from 'react-redux'
import { RouterHistory, Match, Link } from 'react-router-dom'
import { kebabCase } from 'lodash'

import {
  ImageWithPlaceHolder,
  AudioPlayer,
  BodyText,
  Modal,
} from '../../../Components'

import './index.css'
import { URLS, TIPOS_DE_CASO } from '../../../constants'
import { AppState } from '../../../state'
import { url } from '../../../utils'

type OwnProps = {
  history: RouterHistory
  match: Match
}

type StateProps = ReturnType<typeof mapStateToProps>

type Props = StateProps & OwnProps

class Defender extends PureComponent<Props> {
  render() {
    const { defensora, historia, history } = this.props
    const caso = defensora && defensora.casos_id ? defensora.casos_id[0] : null
    const name = caso ? caso.nombre.split(' ') : null

    const color =
      defensora?.tipo_familiar === TIPOS_DE_CASO.TENTATIVAS_DE_FEMINICIDIO.name
        ? TIPOS_DE_CASO.TENTATIVAS_DE_FEMINICIDIO.color
        : 'violet'

    return (
      <>
        <MetaTags>
          <title>{defensora?.nombre}</title>

          <meta property="og:title" content={defensora?.nombre} />
          <meta
            property="og:description"
            content="En México, cada día son asesinadas 10 mujeres."
          />

          <meta name="twitter:card" content={defensora?.nombre} />

          <meta property="og:site_name" content={defensora?.nombre} />
          <meta name="twitter:image:alt" content={defensora?.nombre} />

          <meta property="fb:app_id" content="ocnfeminicidio.mexico" />
          <meta name="twitter:site" content="@OCNF" />
        </MetaTags>

        <div className="defender-container">
          <Modal
            onRequestClose={() => history.push(URLS.FROM_VICTIMS_TO_DEFENDERS)}
          >
            <figure>
              <ImageWithPlaceHolder
                alt={`defensora`}
                src={defensora?.foto}
                defensora="true"
              />
              <figcaption className={`text-color-${color}`}>
                {defensora?.nombre}
              </figcaption>
            </figure>

            {caso ? (
              <div className="shape-defender-card-info">
                {defensora?.tipo_familiar !==
                TIPOS_DE_CASO.TENTATIVAS_DE_FEMINICIDIO.name ? (
                  <div className="defender">
                    <span>{`${defensora?.tipo_familiar}`}</span>
                    <Link
                      className={`text-color-${color}`}
                      to={url(
                        URLS.CASE,
                        kebabCase(caso.tipoCaso_id.nombre),
                        caso.slug
                      )}
                    >{` de ${defensora?.casos_id[0].nombre}`}</Link>
                  </div>
                ) : (
                  <div className="defender">
                    <Link
                      className={`text-color-${color}`}
                      to={url(
                        URLS.CASE,
                        kebabCase(caso.tipoCaso_id.nombre),
                        caso.slug
                      )}
                    >
                      Ver caso
                    </Link>
                    <span>{` de ${defensora?.tipo_familiar}`}</span>
                  </div>
                )}

                {defensora && defensora.audio && defensora.audio[0] ? (
                  <div className="shape-defender-card-audio-player">
                    <AudioPlayer
                      relacion={defensora?.tipo_familiar}
                      name={name ? name[0] : ''}
                      url={defensora.audio[0]}
                    />
                  </div>
                ) : null}

                <div className="shape-defender-card-historia">
                  {historia && historia.length
                    ? historia.map((block, key) => (
                        <BodyText
                          key={key}
                          cols={12}
                          text={block.text}
                          level={key}
                          parent={0}
                        />
                      ))
                    : null}
                </div>
              </div>
            ) : null}
          </Modal>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const { match } = ownProps

  const defensora = state.defensoras.items.find(
    defensora => defensora.slug === match.params.slug
  )

  return {
    slug: match.params.slug,
    defensora,
    historia:
      defensora && defensora.descripcion && defensora.descripcion.blocks
        ? defensora.descripcion.blocks
            .map(h => {
              if (
                h.data.text &&
                typeof h.data.text === 'string' &&
                h.data.text.includes('</b>')
              ) {
                return {
                  text: h.data.text
                    .replace(/&nbsp;/g, '')
                    .replace(/<b>/g, '--split--<BOLD>')
                    .replace(/\//g, '-')
                    .replace(/<-b>/g, '--split--')
                    .split('--split--')
                    .map(boldText => {
                      if (boldText.includes('<BOLD>')) {
                        return {
                          type: 'strong',
                          text: boldText.replace(/<BOLD>/g, ''),
                        }
                      } else {
                        return {
                          type: 'p',
                          text: boldText,
                        }
                      }
                    }),
                }
              }
              if (
                h.data.text &&
                typeof h.data.text === 'string' &&
                !h.data.text.includes('</b>')
              ) {
                return {
                  text: [
                    {
                      type: 'p',
                      text: h.data.text
                        ? h.data.text
                            .replace(/&nbsp;/g, '')
                            .replace(/<mark class="cdx-marker">/g, '')
                            .replace(/\//g, '-')
                            .replace(/<-mark>/g, '')
                        : '',
                    },
                  ],
                }
              }
              if (!h.data.text && h.data.items.length) {
                return {
                  text: [
                    {
                      type: 'ol',
                      items: h.data.items.map(i => i.replace(/&nbsp;/g, '')),
                    },
                  ],
                }
              }
              return null
            })
            .filter(d => d)
        : [],
  }
}

export default connect(mapStateToProps)(Defender)
