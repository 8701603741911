import React, { PureComponent } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import 'moment/locale/es'
import 'moment-timezone'

import { faPlayCircle, faVolumeUp } from '@fortawesome/free-solid-svg-icons'

import {
  Home,
  Intro,
  Campaign,
  Cases,
  Case,
  Defensoras,
  Multimedia,
  Contacto,
} from './Pages'
import { URLS } from './constants'
import getConfig from './config'
import { Auth } from './auth'
import API from './api'
import { Header, Footer, ScrollToTop } from './Components'
import GA from './GoogleAnalytics'

library.add(faPlayCircle, faVolumeUp)

const auth = new Auth(getConfig())
export const api = new API(auth)
moment().locale('es')

type Props = {}
type State = {
  scrollClass: string
}
class App extends PureComponent<Props, State> {
  state = {
    scrollClass: '',
  }

  render() {
    const { scrollClass } = this.state

    return (
      <div className={`App ${scrollClass}`}>
        <Helmet>
          <title>#NOSVANAVERJUNTAS</title>
          <meta name="title" content="#NOSVANAVERJUNTAS" />
          <meta
            name="description"
            content="Formando una sola memoria, apoyándonos y fortaleciendo nuestra red solidaria."
          />
        </Helmet>
        <meta property="og:type" content="website" />
        <BrowserRouter>
          {GA.init() && <GA.RouteTracker />}
          <ScrollToTop>
            <Header
              blockScroll={scrollClass => this.setState({ scrollClass })}
            />
            <Switch>
              <Route exact path={URLS.HOME} component={Home} />
              <Route exact path={URLS.INTRO} component={Intro} />
              <Route exact path={URLS.CAMPAIGN} component={Campaign} />
              <Route exact path={URLS.CASES} component={Cases} />
              <Route exact path={URLS.CASE} component={Case} />
              <Route
                path={URLS.FROM_VICTIMS_TO_DEFENDERS}
                component={Defensoras}
              />
              <Route path={URLS.MULTIMEDIA} component={Multimedia} />
              <Route exact path={URLS.CONTACT} component={Contacto} />
              <Redirect to={URLS.HOME} />
            </Switch>
            <Footer />
          </ScrollToTop>
        </BrowserRouter>
      </div>
    )
  }
}

export default App
