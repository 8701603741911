import React, { PureComponent } from 'react'
import MetaTags from 'react-meta-tags'

import { connect } from 'react-redux'
import { RouterHistory, Match } from 'react-router-dom'
import { Route } from 'react-router-dom'

import { defensorasHero } from '../../Images'
import { AppState, defensorasActions } from '../../state'
import { Template } from '../../Components'
import { DATA } from './constants'
import { URLS } from '../../constants'

import Defender from './Defender'
import DefensoraCard from './DefensoraCard'

import './index.css'

type OwnProps = {
  history: RouterHistory
  location: Location
  match: Match
  blockScroll: (string) => void
}

type StateProps = ReturnType<typeof mapStateToProps>
type ActionsProps = typeof mapActionsToProps

type Props = StateProps & OwnProps & ActionsProps

class Defensoras extends PureComponent<Props> {
  componentDidMount() {
    window.scrollTo(0, 0)

    const { fetchDefensoras, defensoras } = this.props

    if (!defensoras.length) {
      fetchDefensoras()
    }
  }

  componentDidUpdate = prevProps => {
    const { location } = this.props
    const defensoraOpen = location.pathname.includes('/defensoras/')
    if (defensoraOpen) {
      let r = document.getElementById('body')
      r && r.classList.add('blockScroll')
    } else {
      let r = document.getElementById('body')
      r && r.classList.remove('blockScroll')
    }
  }

  render() {
    const { defensoras } = this.props

    return (
      <>
        <MetaTags>
          <title>DE VÍCTIMAS A DEFENSORAS</title>

          <meta property="og:title" content="DE VÍCTIMAS A DEFENSORAS" />
          <meta
            property="og:description"
            content="Este apartado está dedicado a cada una de las mujeres, personas y familias que se convierten en personas defensoras de los derechos humanos y que no solo luchan por un caso, sino que con su voz cobijan a decenas de voces que, por miedo o falta de recursos, se mantienen en el anonimato."
          />

          <meta
            property="og:url"
            content={`https://nosvanaverjuntas.org/defensoras`}
          />
          <meta name="twitter:card" content="#NOSVANAVERJUNTAS" />

          <meta property="og:site_name" content="#NOSVANAVERJUNTAS" />
          <meta name="twitter:image:alt" content="#NOSVANAVERJUNTAS" />

          <meta property="fb:app_id" content="ocnfeminicidio.mexico" />
          <meta name="twitter:site" content="@OCNF" />
        </MetaTags>

        <div className="block page defenders">
          <div className="shape-container">
            <div className="shape-row">
              <div className="shape-col-12-np">
                <img
                  src={defensorasHero}
                  alt="defensoras - nos van a ver juntas"
                />
              </div>
            </div>
          </div>
          <div className="shape-full-with-block">
            <div className="shape-container">
              <Template data={DATA} />
            </div>

            <div className="shape-defensoras-wrapper">
              <div className="shape-container">
                <div className="shape-row">
                  <div className="shape-col-12-np">
                    {defensoras && defensoras.length
                      ? defensoras.map((defensora, key) => {
                          return (
                            <DefensoraCard key={key} defender={defensora} />
                          )
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Route
          exact
          path={URLS.DEFENDER}
          component={Defender}
          history={this.props.history}
        />
      </>
    )
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  return {
    defensoras: state.defensoras.items,
  }
}

const mapActionsToProps = {
  fetchDefensoras: defensorasActions.fetchResources,
}

export default connect(mapStateToProps, mapActionsToProps)(Defensoras)
