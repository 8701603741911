import React, { PureComponent } from 'react'
import Slider from 'react-animated-slider'
import 'react-animated-slider/build/horizontal.css'

import { slide1, slide2, slide3 } from '../../Images'
import { Button } from '../../Components'
import { URLS } from '../../constants'

const content = [
  {
    image: slide1,
    smTitle: 'MULTIMEDIA',
    title: '#NOSVANAVERJUNTAS',
    description:
      'Fuertes y organizadas, contribuimos a visibilizar y erradicar la violencia feminicida en México.',
    copy:
      'Indignación, Memoria y Esperanza: #NOSVANAVERJUNTAS ¡Conoce la campaña!',
    button: 'Ver más',
    link: URLS.MULTIMEDIA,
    color: 'purple',
    outline: true,
  },
  {
    image: slide2,
    smTitle: 'DE VÍCTIMAS A DEFENSORAS',
    title: '#NOSVANAVERJUNTAS',
    description:
      'Transformando el dolor en esperanza, apoyándo y reconociendo la labor de familias y madres defensoras.',
    copy:
      '¡Súmate a más personas en este camino hacia la justicia y escucha sus historias!',
    button: 'Ver más',
    link: URLS.FROM_VICTIMS_TO_DEFENDERS,
    color: 'violet',
    outline: false,
  },
  {
    image: slide3,
    smTitle: 'PARTICIPA',
    title: '#NOSVANAVERJUNTAS',
    description:
      'Formando una sola memoria, apoyándonos y fortaleciendo nuestra red solidaria.',
    button: 'Participa',
    link: URLS.CONTACT,
    color: 'orange',
    outline: true,
  },
]

type Props = { changePage: (url: string) => void }
type State = {
  current: number
  page: number | null
}

class SliderWrapper extends PureComponent<Props, State> {
  state = {
    current: 0,
    page: null,
  }

  render() {
    const { current } = this.state
    const { changePage } = this.props

    return (
      <div className="slider-container">
        <div className="pages-indicator">
          {content.map((p, key) => (
            <span
              key={key}
              className={`page-indicator ${key === current ? 'active' : ''}`}
            ></span>
          ))}
        </div>
        <Slider
          className="slider-wrapper"
          autoplay={13330000}
          infinite
          touchDisabled={true}
          onSlideChange={event => this.setState({ current: event.slideIndex })}
          current={this.state.page}
        >
          {content.map((item, index) => (
            <div key={index} className="slider-content">
              <div className="inner">
                <section className="left">
                  <img src={item.image} alt="" />
                </section>
                <section className="right">
                  <span className="title">{item.smTitle}</span>
                  <h2 className={`text-color-${item.color}`}>{item.title}</h2>
                  <p className="copy">{item.description}</p>
                  <p className={`text text-color-${item.color}`}>{item.copy}</p>
                  <Button
                    text={item.button}
                    color={item.color}
                    size="md"
                    type="button"
                    outline={item.outline}
                    onClick={() => changePage(item.link)}
                  />
                </section>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

export default SliderWrapper
