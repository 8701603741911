import { FetchWrapper } from './FetchWrapper'

const adminAPIMixin = (Base: typeof FetchWrapper) =>
  class extends Base {
    // #region GET
    fetchTiposDeCasos = async () => {
      return this.get({
        endpoint: '/tipos-caso',
      })
    }

    fetchCasos = async parms => {
      if (parms.slug) {
        return this.get({
          endpoint: '/casos-publicados/' + parms.slug,
        })
      }
    }

    fetchFeminicidios = async () => {
      return this.get({
        endpoint: '/casos-publicados/feminicidio',
      })
    }

    fetchTentativas = async () => {
      return this.get({
        endpoint: '/casos-publicados/tentativas-de-feminicidio',
      })
    }

    fetchOtrosCasos = async () => {
      return this.get({
        endpoint: '/casos-publicados/otros-casos-de-violencia',
      })
    }

    fetchCaso = async parms => {
      if (parms.slugTipo && parms.slugCaso) {
        return this.get({
          endpoint: `/caso-publicado/${parms.slugTipo}/${parms.slugCaso}`,
        })
      }
    }

    fetchDefensoras = async () => {
      return this.get({
        endpoint: '/defensoras-publicadas',
      })
    }

    fetchAlerts = async () => {
      return this.get({
        endpoint: '/alertas-vigentes',
      })
    }

    fetchMultimedia = async () => {
      return this.get({
        endpoint: '/multimedia',
      })
    }

    // #region POST

    createContacto = async body => {
      return this.post({
        endpoint: '/contacto',
        body,
      })
    }
    // #endregion GET
  }

export default adminAPIMixin
