import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

import { Image } from '../../../Components'
import { URLS } from '../../../constants'
import { url } from '../../../utils'
import { IMultimedia } from '../../../state/Multimedia/types'
import { orderBy } from 'lodash'

type Props = {
  infografias: IMultimedia[]
}

class Infografias extends PureComponent<Props> {
  render() {
    const { infografias } = this.props
    return (
      <>
        {infografias
          ? orderBy(infografias, 'titulo').map(infografia => (
              <div className="infigrafias">
                <div className="shape-row">
                  <div className="shape-col-1-np" />
                  <div className="shape-col-10-np">
                    <Link to={url(URLS.MULTIMEDIA_INFOGRAFIA, infografia.slug)}>
                      <figure>
                        <Image
                          parent={0}
                          level={0}
                          cols={12}
                          img={infografia.imagen || ''}
                          figcaption={infografia.descripcion}
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="shape-col-1-np" />
                </div>
              </div>
            ))
          : null}
      </>
    )
  }
}

export default Infografias
