import React, { PureComponent } from 'react'
import { Hero, DotText, BodyText, Video, Image } from '../../Components'

type Props = {
  data: any
  level: number
  nc: number
}

class Col extends PureComponent<Props> {
  render() {
    const { data, level, nc } = this.props

    return (
      <div className={`shape-col-${nc} shape-col-12-md level-${level}`}>
        {data && data.length
          ? data.map((d, key) => {
              if (d.type === 'hero') {
                return <Hero key={key} {...d} level={key} parent={level} />
              }

              if (d.type === 'dotText') {
                return <DotText key={key} {...d} level={key} parent={level} />
              }

              if (d.type === 'text') {
                return <BodyText key={key} {...d} level={key} parent={level} />
              }

              if (d.type === 'video') {
                return <Video key={key} {...d} level={key} parent={level} />
              }

              if (d.type === 'img') {
                return <Image key={key} {...d} level={key} parent={level} />
              }

              if (d.type === 'placeholder') {
                return (
                  <div
                    key={key}
                    className={`shape-col-${d.cols}-np shape-text-placeholder`}
                  />
                )
              }
              return null
            })
          : null}
      </div>
    )
  }
}

export default Col
