import development from './dev'
import test from './test'
import production from './prod'

const settings = {
  development,
  test,
  production,
}

const env = process.env.NODE_ENV || 'production'

export default () => settings[env]
