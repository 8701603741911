import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { ImageWithPlaceHolder } from '../../Components'
import { URLS, TIPOS_DE_CASO_OPTIONS } from '../../constants'
import { url } from '../../utils'

type Props = {
  type: string
  foto?: string
  slug: string
  nombre: string
  fecha: moment.Moment
  ubicacion: string
}

const VictimCard = ({ slug, nombre, fecha, foto, ubicacion, type }: Props) => {
  const nameSplit = nombre.split(' ')

  const color =
    TIPOS_DE_CASO_OPTIONS.find(t => t.slug === type)?.color || 'violet'

  return (
    <Link to={url(URLS.CASE, type, slug)}>
      <div className="shape-victim-card">
        <div className="shape-victim-card-img-wrapper">
          <ImageWithPlaceHolder alt="nosvanaverjuntas-victima" src={foto} />
        </div>
        <div className="shape-victim-card-name">
          {nameSplit.map((n, key) => (
            <span
              key={key}
              className={`${
                key === 0 ? 'bold  text-color-' + color : 'text-color-black '
              }`}
            >
              {n}
            </span>
          ))}
        </div>
        <div className="shape-victim-card-footer">
          <span className="text-color-black ">
            {fecha.format('DD.MMMYYYY').toUpperCase()}
          </span>
          <span className={`text-color-${color}`}>{ubicacion}</span>
        </div>
      </div>
    </Link>
  )
}

export default VictimCard
