import React, { PureComponent } from 'react'
import MetaTags from 'react-meta-tags'

import { connect } from 'react-redux'
import { reduxForm, Field, getFormValues, InjectedFormProps } from 'redux-form'
import { RouterHistory, Match } from 'react-router-dom'
import { toast } from 'react-toastify'

import { AppState, contactoActions } from '../../state'

import { renderInput, renderTextarea } from '../../Components/Fields'
import { Button, MediaIconSVG } from '../../Components'

import './index.css'
import validate from './validate'
import { MEDIA } from '../../constants'

type OwnProps = {
  history: RouterHistory
  location: Location
  match: Match
  blockScroll: (string) => void
}

type StateProps = ReturnType<typeof mapStateToProps>
type ActionsProps = typeof mapActionsToProps

type Props = StateProps & OwnProps & ActionsProps

class Contacto extends PureComponent<
  Props & InjectedFormProps<FormData, Props>
> {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    const { isSendingMessage, contactValueForms, apiError } = this.props

    if (prevProps.isSendingMessage && !isSendingMessage) {
      if (apiError) {
        return toast(apiError, {
          className: 'toastify-container bg-color-orange alert',
          position: 'bottom-right',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      } else {
        Object.keys(contactValueForms).map(value =>
          this.props.change(value, '')
        )
        return toast('¡Mensaje enviado correctamente!', {
          className: 'toastify-container bg-color-violet alert',
          position: 'bottom-right',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      }
    }
  }

  sendValues = () => {
    const { contactValueForms } = this.props

    this.props.createContacto(contactValueForms)
  }

  render() {
    return (
      <>
        <MetaTags>
          <title>CUENTA CON NOSOTRAS #NOSVANAVERJUNTAS</title>

          <meta
            property="og:title"
            content="CUENTA CON NOSOTRAS #NOSVANAVERJUNTAS"
          />
          <meta
            property="og:description"
            content="En México, cada día son asesinadas 10 mujeres."
          />

          <meta
            name="twitter:card"
            content="CUENTA CON NOSOTRAS #NOSVANAVERJUNTAS"
          />

          <meta
            property="og:site_name"
            content="CUENTA CON NOSOTRAS #NOSVANAVERJUNTAS"
          />
          <meta
            name="twitter:image:alt"
            content="CUENTA CON NOSOTRAS #NOSVANAVERJUNTAS"
          />

          <meta property="fb:app_id" content="ocnfeminicidio.mexico" />
          <meta name="twitter:site" content="@OCNF" />
        </MetaTags>

        <div className="block page contact">
          <div className="shape-full-with-block">
            <div className="shape-container">
              <div className="shape-row">
                <div className="shape-col-6 shape-col-12-md">
                  <div className="contacto-redes-left">
                    <div className="title text-color-violet mb">
                      Nuestras Redes
                    </div>

                    <div className="red-row">
                      <div className="red-icon">
                        <MediaIconSVG type={MEDIA.FACEBOOK} />
                      </div>
                      <div className="red-link">
                        <a
                          href="https://www.facebook.com/ocnfeminicidio.mexico"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="name">Facebook</span>
                          <span>@OCNFeminicidio México</span>
                        </a>
                      </div>
                    </div>

                    <div className="red-row">
                      <div className="red-icon">
                        <MediaIconSVG type={MEDIA.INSTAGRAM} />
                      </div>
                      <div className="red-link">
                        <a
                          href="https://www.instagram.com/ocnfeminicidiomexico/?hl=es-la"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="name">Instagram</span>
                          <span>@OCNFeminicidioMéxico</span>
                        </a>
                      </div>

                      <div className="red-row">
                        <div className="red-icon">
                          <MediaIconSVG type={MEDIA.TWITTER} />
                        </div>
                        <div className="red-link">
                          <a
                            href="https://twitter.com/OCNF"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="name">Twitter</span>
                            <span>@OCNF</span>
                          </a>
                        </div>
                      </div>

                      <div className="red-row">
                        <div className="red-icon">
                          <MediaIconSVG type={MEDIA.YOUTUBE} />
                        </div>
                        <div className="red-link">
                          <a
                            href="https://www.youtube.com/channel/UCpWpvc6tiIlj9GzB_z4t1ig"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="name">YouTube</span>
                            <span>Observatorio Feminicidio</span>
                          </a>
                        </div>
                      </div>

                      <div className="title text-color-violet">Escríbenos</div>
                      <div className="red-row">
                        <a
                          href="mailto:ocnf@observatoriofeminicidiomexico.org"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          ocnf@observatoriofeminicidiomexico.org
                        </a>
                      </div>

                      <div className="title text-color-violet">Visítanos</div>
                      <div className="red-row">
                        <a
                          href="www.observatoriofeminicidiomexico.org"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.observatoriofeminicidiomexico.org
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shape-col-6  shape-col-12-md">
                  <div className="contacto-redes-right">
                    <div className="title text-color-violet mb">
                      Cuenta con nosotras
                    </div>
                    <div className="red-row">
                      ¿Necesitas ayuda? o tienes alguna duda o comentario,
                      escríbenos.
                    </div>
                    <div className="red-row mb">Queremos escucharte.</div>
                    <form>
                      <Field
                        name="name"
                        label="Nombre"
                        type="text"
                        component={renderInput}
                      />
                      <Field
                        name="correo"
                        label="Email"
                        type="email"
                        component={renderInput}
                      />
                      <Field
                        name="subject"
                        label="Asunto"
                        type="text"
                        component={renderInput}
                      />
                      <Field
                        name="message"
                        label="Comentarios"
                        type="text"
                        component={renderTextarea}
                      />
                      <Button
                        text="ENVIAR"
                        size="md"
                        color="violet"
                        type="button"
                        onClick={() => this.sendValues()}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const reduxFormContainer = reduxForm({
  form: 'contactFrom',
  enableReinitialize: true,
  validate,
  // @ts-ignore
})(Contacto)

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const contactValueForms = getFormValues('contactFrom')(state)

  return {
    defensoras: state.defensoras.items,
    contactValueForms,
    isSendingMessage: state.contacto.isCreating,
    apiError:
      state.contacto.code === 401
        ? 'Hubo un problema que hubo en problema al enviar tu mensaje'
        : null,
  }
}

const mapActionsToProps = {
  createContacto: contactoActions.createResource,
}

export default connect(mapStateToProps, mapActionsToProps)(reduxFormContainer)
