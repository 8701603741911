import { WrappedFieldProps } from 'redux-form'

import React from 'react'

type Props = {
  label: string
} & React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps

const Input = (props: Props) => {
  const {
    label,
    meta: { error, touched },
    input,
    ...rest
  } = props

  return (
    <div className={`form-group ${touched && error ? ' error' : ''}`}>
      <input
        placeholder={label}
        className="input"
        {...rest}
        {...input}
        autoComplete="off"
      />
      {touched && error && (
        <span className="error-feedback text-color-orange">{error}</span>
      )}
    </div>
  )
}

export default Input
