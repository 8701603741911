import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { orderBy } from 'lodash'
import {
  Field,
  reduxForm,
  formValueSelector,
  InjectedFormProps,
} from 'redux-form'
import Sticky from 'react-sticky-el'

import { renderRadioField, renderSelect } from '../Fields'

import {
  AppState,
  tiposDeCasosActions,
  casosActions,
  feminicidiosActions,
  tentativasActions,
  otrosCasosActions,
} from '../../state'

import { LOCATIONS } from '../../constants'

import renderMapField from './renderMapField'
import './index.css'

type StateProps = ReturnType<typeof mapStateToProps>
type ActionsProps = typeof mapActionsToProps

type OwnProps = {
  noSelect?: boolean
}

type Props = StateProps & ActionsProps & OwnProps

class MapContainer extends PureComponent<
  Props & InjectedFormProps<FormData, Props>
> {
  componentDidMount() {
    this.props.change('estados', [])
    this.props.change('tipoDeCaso', 'feminicidio')
    const { fetchTiposDeCasos, tiposDeCasos } = this.props

    if (!tiposDeCasos.length) {
      fetchTiposDeCasos()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetchCasos,
      fetchFeminicidio,
      fetchTentativas,
      fetchOtrosCasos,
      selectedTipoDeCaso,
      isFetchingCasos,
      feminicidio,
      tentativas,
      otrosCasos,
      casos,
    } = this.props

    if (
      selectedTipoDeCaso !== prevProps.selectedTipoDeCaso &&
      !isFetchingCasos
    ) {
      this.props.change('estados', null)

      if (selectedTipoDeCaso === 'feminicidio' && !feminicidio.length) {
        fetchFeminicidio()
      }
      if (
        selectedTipoDeCaso === 'tentativas-de-feminicidio' &&
        !tentativas.length
      ) {
        fetchTentativas()
      }
      if (
        selectedTipoDeCaso === 'otros-casos-de-violencia' &&
        !otrosCasos.length
      ) {
        fetchOtrosCasos()
      }

      if (
        selectedTipoDeCaso !== 'feminicidio' ||
        (selectedTipoDeCaso !== 'tentativas-de-feminicidio' &&
          selectedTipoDeCaso !== 'otros-casos-de-violencia' &&
          !casos.length)
      ) {
        fetchCasos({ slug: selectedTipoDeCaso })
      }
    }
  }

  render() {
    const {
      casosForMap,
      tiposDeCasosOptions,
      isFetchingCasos,
      noSelect,
    } = this.props
    const casosHASCId = casosForMap.map(estado => estado.HASC)

    return (
      <div className="map-wrapper">
        <Field name="estados" component={renderMapField} data={casosForMap} />

        <Field
          name="tipoDeCaso"
          component={renderRadioField}
          options={tiposDeCasosOptions}
          disabled={isFetchingCasos}
        />

        {!noSelect ? (
          <Sticky className="sticky-select">
            <Field
              name="estados"
              component={renderSelect}
              options={LOCATIONS.filter(estado =>
                casosHASCId.includes('MX.' + estado.value)
              )}
              multi
              width="200px"
            />
          </Sticky>
        ) : null}
      </div>
    )
  }
}

const reduxFormContainer = reduxForm({
  enableReinitialize: true,
  form: 'mapForm',
  // @ts-ignore
})(MapContainer)

const tipoDeCasoColors = [
  'rgba(192, 79, 181, 1)',
  'rgba(255, 179, 113, 1)',
  'rgba(109, 74, 255, 1)',
]

const mapFormValueSelector = formValueSelector('mapForm')

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const tiposDeCasos = state.tiposDeCasos.items
  const selectedTipoDeCaso = mapFormValueSelector(state, 'tipoDeCaso')
  const selectedEstadoSelect = mapFormValueSelector(state, 'estados')

  const selectedEstadoMapIds =
    selectedEstadoSelect && selectedEstadoSelect.length
      ? selectedEstadoSelect.map(estado => 'MX.' + estado.value)
      : []

  const { noSelect } = ownProps

  const tiposDeCasosOptions = orderBy(
    tiposDeCasos,
    ['order'],
    ['asc', 'desc']
  ).map((tipo, key) => ({
    id: key,
    label: tipo.nombre,
    value: tipo.slug,
    color: tipoDeCasoColors[key],
  }))

  const tipoDeCasoInitial = tiposDeCasosOptions ? tiposDeCasosOptions[0] : null
  const selectedTipoDeCasoObj = tiposDeCasosOptions.find(
    t => t.value === selectedTipoDeCaso
  )

  const casos = tipo => {
    switch (tipo) {
      case 'feminicidio':
        return state.feminicidios.items.map(caso => caso.feminicidio.HASC)
      case 'tentativas-de-feminicidio':
        return state.tentativas.items.map(caso => caso.feminicidio.HASC)
      case 'otros-casos-de-violencia':
        return state.otrosCasos.items.map(caso => caso.feminicidio.HASC)
      default:
        return state.casos.items.map(caso => caso.feminicidio.HASC)
    }
  }

  const tipoDeCasoColor =
    selectedTipoDeCasoObj && selectedTipoDeCasoObj.color
      ? selectedTipoDeCasoObj.color.replace('1)', '0.70)')
      : tipoDeCasoColors[0].replace('1)', '0.70)')

  const HASCIds = casos(selectedTipoDeCaso)

  const uniqueHASC =
    HASCIds && HASCIds.length >= 1
      ? // @ts-ignore
        [...new Set(HASCIds)]
      : []

  return {
    selectedEstadoSelect,
    tiposDeCasos,
    selectedTipoDeCaso,
    selectedTipoDeCasoObj,
    isFetchingCasos: state.casos.isFetching,
    casosForMap: uniqueHASC.map(id => {
      return {
        HASC: id,
        color:
          selectedEstadoMapIds.length &&
          selectedEstadoMapIds.includes(id) &&
          !noSelect
            ? tipoDeCasoColor.replace('0.70)', '1)')
            : tipoDeCasoColor,
      }
    }),
    tiposDeCasosOptions,
    feminicidio: state.feminicidios.items,
    tentativas: state.tentativas.items,
    otrosCasos: state.otrosCasos.items,
    casos: state.casos.items,
    initialValues: {
      tipoDeCaso: tipoDeCasoInitial ? tipoDeCasoInitial.value : 'feminicidio',
    },
  }
}

const mapActionsToProps = {
  fetchTiposDeCasos: tiposDeCasosActions.fetchResources,
  fetchCasos: casosActions.fetchResources,
  fetchFeminicidio: feminicidiosActions.fetchResources,
  fetchTentativas: tentativasActions.fetchResources,
  fetchOtrosCasos: otrosCasosActions.fetchResources,
}

export default connect(mapStateToProps, mapActionsToProps)(reduxFormContainer)
