import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

import { ImageWithPlaceHolder } from '../../../Components'
import { URLS } from '../../../constants'
import { url } from '../../../utils'

import { IMultimedia } from '../../../state/Multimedia/types'

type Props = {
  videos: IMultimedia[]
}

class Videos extends PureComponent<Props> {
  render() {
    const { videos } = this.props
    return (
      <>
        {videos.map((video, key) => {
          const titleSplit = video.titulo.split(' ')
          return (
            <Link key={key} to={url(URLS.MULTIMEDIA_VIDEO, video.slug)}>
              <div className="shape-video-full">
                <div className="shape-video-full-overlay" />
                <div className="shape-video-title">
                  <div className="video-button white">
                    <div className="arrow"></div>
                  </div>
                  {titleSplit.map((t, key) => (
                    <span className={`${key === 0 ? 'first' : ''}`}>{t}</span>
                  ))}
                </div>
                <figure>
                  <ImageWithPlaceHolder
                    alt={`${video.titulo}-${video.serie}`}
                    src={video.imagen}
                    defensora="true"
                  />
                </figure>
              </div>
            </Link>
          )
        })}
      </>
    )
  }
}

export default Videos
