import { takeEvery, take, race, put } from 'redux-saga/effects'

const actionsWhiteList = ['FETCH_', 'CREATE_', 'UPDATE_', 'DELETE_']
const actionsBlackList = ['_REQUEST', '_SUCCESS', '_FAILURE']

export function monitorableAction(action) {
  return (
    actionsWhiteList.some(fragment => action.type.includes(fragment)) &&
    actionsBlackList.every(fragment => !action.type.includes(fragment))
  )
}

export function* monitorSagas() {
  yield takeEvery(monitorableAction, _monitorAction)
}

// Exported only for unit testing, to not use in the apps
export function* _monitorAction(monitoredAction) {
  const { fail } = yield race({
    success: take(monitoredAction.type + '_SUCCESS'),
    fail: take(monitoredAction.type + '_FAILURE'),
  })

  if (fail && fail.code === 401) {
    yield put({ type: 'REFRESH_TOKEN' })

    const { success } = yield race({
      success: take('REFRESH_TOKEN_SUCCESS'),
      fail: take('REFRESH_TOKEN_FAILURE'),
    })

    if (success) {
      yield put(monitoredAction)
    }
  }
}
