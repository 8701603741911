import React, { PureComponent } from 'react'
import MetaTags from 'react-meta-tags'

import { connect } from 'react-redux'
import { RouterHistory, Match } from 'react-router-dom'
import ReactPlayer from 'react-player'

import { Modal } from '../../../Components'
import { URLS } from '../../../constants'
import { AppState } from '../../../state'

import './index.css'

type StateProps = ReturnType<typeof mapStateToProps>

type OwnProps = {
  history: RouterHistory
  match: Match
}

type Props = OwnProps & StateProps

class Video extends PureComponent<Props> {
  render() {
    const { history, video } = this.props
    return (
      <>
        <MetaTags>
          <title>{video?.titulo}</title>

          <meta property="og:title" content={video?.titulo} />
          <meta
            property="og:description"
            content={video?.descripcion || video?.titulo}
          />
          <meta property="og:url" content={video?.imagen || ''} />
          <meta name="twitter:card" content={video?.titulo} />

          <meta property="og:site_name" content={video?.titulo} />
          <meta name="twitter:image:alt" content={video?.titulo} />

          <meta property="fb:app_id" content="ocnfeminicidio.mexico" />
          <meta name="twitter:site" content="@OCNF" />
        </MetaTags>

        <div className="multimedia-video-modal">
          <Modal onRequestClose={() => history.push(URLS.MULTIMEDIA)}>
            <div className="shape-multimedia-video">
              <div className="shape-multimedia-video-player">
                <ReactPlayer
                  className="react-player"
                  width="100%"
                  height="80vh"
                  url={video?.link}
                />
              </div>
            </div>
          </Modal>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const {
    params: { slug },
  } = ownProps.match

  return {
    video: state.multimedia.items.find(m => m.slug === slug),
  }
}

export default connect(mapStateToProps)(Video)
