import React from 'react'
import { MEDIA } from '../../constants'

import './index.css'

const getSvg = ({ type }) => {
  switch (type) {
    case MEDIA.FACEBOOK:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="75"
          height="75"
          viewBox="0 5 75 75"
        >
          <defs>
            <clipPath>
              <rect width="75" height="75" />
            </clipPath>
          </defs>
          <g data-name="facebook" className="cls-1">
            <rect className="cls-3" width="75" height="75" />
            <g transform="translate(6 5)">
              <path
                className="cls-2"
                d="M-346.36,6929.8h2.657v-15.116h4.985l.84-5.971H-343.7v-4.068a2.294,2.294,0,0,1,2.282-2.293h3.766v-5.5l-4.733-.189a6.966,6.966,0,0,0-7.419,7.12v4.927h-4.983v5.971h4.983V6929.8Z"
                transform="translate(377.401 -6880.561)"
              />
              <path
                className="cls-2"
                d="M-325.418,6953.617h-19.912a21.3,21.3,0,0,1-21.221-21.336v-22.658a21.305,21.305,0,0,1,21.221-21.339h19.912a21.305,21.305,0,0,1,21.22,21.339v22.658a21.3,21.3,0,0,1-21.22,21.336m-19.912-61a16.74,16.74,0,0,0-11.944,5,16.923,16.923,0,0,0-4.971,12.011v22.658a16.933,16.933,0,0,0,4.971,12.009,16.744,16.744,0,0,0,11.944,5h19.912a16.744,16.744,0,0,0,11.944-5,16.933,16.933,0,0,0,4.971-12.009v-22.658a16.923,16.923,0,0,0-4.971-12.011,16.74,16.74,0,0,0-11.944-5Z"
                transform="translate(366.552 -6888.284)"
              />
            </g>
          </g>
        </svg>
      )
    case MEDIA.INSTAGRAM:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="75"
          height="75"
          viewBox="0 5 75 75"
        >
          <defs>
            <clipPath>
              <rect width="75" height="75" />
            </clipPath>
          </defs>
          <g className="cls-1">
            <rect className="cls-3" width="75" height="75" />
            <g id="Grupo_4" data-name="Grupo 4" transform="translate(7.159 6)">
              <path
                className="cls-2"
                d="M-325.685,7028.624a16.634,16.634,0,0,1-16.633,16.634,16.633,16.633,0,0,1-16.633-16.634,16.633,16.633,0,0,1,16.633-16.633,16.634,16.634,0,0,1,16.633,16.633m-16.713-10.879a10.893,10.893,0,0,0-10.892,10.892,10.892,10.892,0,0,0,10.892,10.891,10.892,10.892,0,0,0,10.892-10.891,10.893,10.893,0,0,0-10.892-10.892"
                transform="translate(372.395 -6997.002)"
              />
              <path
                className="cls-2"
                d="M-335.679,7013.747a3.938,3.938,0,0,1-3.939,3.938,3.937,3.937,0,0,1-3.937-3.938,3.938,3.938,0,0,1,3.937-3.938,3.938,3.938,0,0,1,3.939,3.938"
                transform="translate(386.596 -6999.018)"
              />
              <path
                className="cls-2"
                d="M-326.352,7066.736h-19.166a20.45,20.45,0,0,1-20.426-20.426v-21.688a20.45,20.45,0,0,1,20.426-20.429h19.166a20.449,20.449,0,0,1,20.426,20.429v21.688a20.449,20.449,0,0,1-20.426,20.426m-19.166-58.4a16.165,16.165,0,0,0-11.5,4.784,16.17,16.17,0,0,0-4.785,11.5v21.688a16.155,16.155,0,0,0,4.785,11.5,16.157,16.157,0,0,0,11.5,4.788h19.166a16.158,16.158,0,0,0,11.5-4.788,16.153,16.153,0,0,0,4.783-11.5v-21.688a16.168,16.168,0,0,0-4.783-11.5,16.166,16.166,0,0,0-11.5-4.784Z"
                transform="translate(365.944 -7004.194)"
              />
            </g>
          </g>
        </svg>
      )
    case MEDIA.TWITTER:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="75"
          height="75"
          viewBox="0 5 75 75"
        >
          <defs>
            <clipPath>
              <rect width="75" height="75" />
            </clipPath>
          </defs>
          <g className="cls-1">
            <rect className="cls-3" width="75" height="75" />
            <path
              className="cls-2"
              d="M-328.273,6950.695a12.542,12.542,0,0,1-2.267.625,6.759,6.759,0,0,0,1.64-1.816,4.793,4.793,0,0,0,.484-1.014c.036-.118.063-.244-.05-.338s-.21-.037-.313.015-.2.094-.3.148a11.582,11.582,0,0,1-2.4.955c-.6.155-.725.207-.892.1a5.981,5.981,0,0,0-2.111-1.414h0l-.012,0a6,6,0,0,0-2.67-.406,5.56,5.56,0,0,0-2.972.887l.017,0c-.069.043-.144.079-.211.124l-.031.016,0,0a6.345,6.345,0,0,0-2.4,2.909,6.651,6.651,0,0,0-.4,3.261c.027.3-.025.371-.329.35-.211-.016-.425-.038-.634-.067a17.4,17.4,0,0,1-6.365-2.074,17.2,17.2,0,0,1-4.8-3.939c-.267-.318-.477-.3-.675.062a6.031,6.031,0,0,0-.617,3.7,6.149,6.149,0,0,0,2.332,4.115,7.044,7.044,0,0,1-1.918-.55c-.342-.157-.492-.059-.506.32a3.075,3.075,0,0,0,.029.547,6.259,6.259,0,0,0,2.186,4,5.556,5.556,0,0,0,2.282,1.234,7.821,7.821,0,0,1-1.963.011c-.171-.025-.286.092-.3.275a.667.667,0,0,0,.073.293,6.28,6.28,0,0,0,4.681,3.876,2.191,2.191,0,0,1,.558.111c-.027.112-.123.141-.2.189a12.156,12.156,0,0,1-3.8,1.745,13.856,13.856,0,0,1-3.314.437,6.312,6.312,0,0,0-1.061-.045c-.075.042-.154.078-.169.175,0,0,5.519,4.722,14.268,2.208q.121-.029.242-.062c.081-.023.158-.04.24-.064l.01-.019a16.434,16.434,0,0,0,4.27-1.953,17.015,17.015,0,0,0,4.124-3.761,18.515,18.515,0,0,0,3.257-6.3,18.071,18.071,0,0,0,.734-5.323.692.692,0,0,1,.292-.625,11.814,11.814,0,0,0,2.343-2.381.668.668,0,0,0,.183-.584c-.2-.179-.39-.027-.567.041"
              transform="translate(380.737 -6922.534)"
            />
            <path
              className="cls-2"
              d="M-325.418,7001.875h-19.912a21.3,21.3,0,0,1-21.221-21.335v-22.658a21.3,21.3,0,0,1,21.221-21.338h19.912a21.3,21.3,0,0,1,21.22,21.338v22.658a21.3,21.3,0,0,1-21.22,21.335m-19.912-61a16.75,16.75,0,0,0-11.944,5,16.926,16.926,0,0,0-4.971,12.012v22.658a16.932,16.932,0,0,0,4.971,12.011,16.751,16.751,0,0,0,11.944,5h19.912a16.751,16.751,0,0,0,11.944-5,16.932,16.932,0,0,0,4.971-12.011v-22.658a16.926,16.926,0,0,0-4.971-12.012,16.75,16.75,0,0,0-11.944-5Z"
              transform="translate(372.542 -6932.543)"
            />
          </g>
        </svg>
      )
    case MEDIA.YOUTUBE:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="75"
          height="75"
          viewBox="0 5 75 75"
        >
          <defs>
            <clipPath>
              <rect width="75" height="75" />
            </clipPath>
          </defs>
          <g className="cls-1">
            <rect className="cls-3" width="75" height="75" />
            <g transform="translate(4.886 14)">
              <g>
                <path
                  className="cls-2"
                  d="M-324.667,7109.3l-.348,0c-4.735-.033-23.421-.033-28.157,0a60.5,60.5,0,0,1-9.274-.76c-3.424-.525-7.686-2.632-8.532-9.669a89.3,89.3,0,0,1,.238-26.069c.925-5.337,4.16-8.364,9.887-9.257,2.847-.444,40.672-.444,43.519,0,5.727.893,8.96,3.917,9.885,9.257v0a89.225,89.225,0,0,1,.24,26.07c-.852,7.037-5.108,9.142-8.53,9.667A58.86,58.86,0,0,1-324.667,7109.3Zm-14.426-42.229c-9.991,0-19.981.09-21.168.27-4.11.639-6.048,2.411-6.69,6.116a85.515,85.515,0,0,0-.211,24.953c.607,5.038,3.314,6.024,5.3,6.331a55.444,55.444,0,0,0,8.659.713c4.75-.034,23.471-.034,28.217,0a56.408,56.408,0,0,0,8.664-.713c1.982-.307,4.689-1.293,5.3-6.331a85.656,85.656,0,0,0-.211-24.954c-.642-3.7-2.582-5.476-6.69-6.115C-319.111,7067.165-329.1,7067.074-339.092,7067.074Z"
                  transform="translate(371.808 -7063.213)"
                />
              </g>
              <g transform="translate(26.73 13.809)">
                <path
                  className="cls-2"
                  d="M-357.9,7088.809V7070.4l16.069,9.2-16.069,9.217"
                  transform="translate(357.904 -7070.396)"
                />
              </g>
            </g>
          </g>
        </svg>
      )
  }
}

const Icon = type => {
  return <div className="shape-icon">{getSvg(type)}</div>
}
export default Icon
