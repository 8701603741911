import { WrappedFieldProps } from 'redux-form'

import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import React from 'react'

const animatedComponents = makeAnimated()

type Option = { [valueOrLabelKey: string]: number | string }

type ReactSelectProps = {
  options: Option[]
}

type Props = React.InputHTMLAttributes<HTMLInputElement> &
  ReactSelectProps &
  WrappedFieldProps

const renderSelect = (props: Props) => {
  const {
    input: { value, onChange, onBlur },
    meta: { error, touched },
    options,
    ...otherProps
  } = props
  // crear una class para actualizar el onChange
  const createSelect = () => {
    return (
      <Select
        {...otherProps}
        // @ts-ignore
        value={value}
        isMulti
        isClearable={false}
        closeMenuOnSelect={true}
        components={animatedComponents}
        options={options}
        placeholder="Filtra los casos por estado..."
        onChange={selected => {
          // @ts-ignore
          onBlur()
          onChange(selected)
        }}
      />
    )
  }

  return (
    <div className={`dd-form-group${touched && error ? ' error' : ''}`}>
      {createSelect()}
      {touched && error && <span className="dd-error-feedback">{error}</span>}
    </div>
  )
}

export default renderSelect
