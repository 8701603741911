import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

import { Hero, ImageWithPlaceHolder } from '../../../Components'

import './index.css'
import { url } from '../../../utils'
import { URLS } from '../../../constants'
import { IMultimedia } from '../../../state/Multimedia/types'

type Props = {
  level: number
  serie: {
    name: string
    postales: IMultimedia[]
  }
}

type State = {
  open: boolean
}

class PostalesListWrapper extends PureComponent<Props, State> {
  state = {
    open: true,
  }

  render() {
    const { serie, level } = this.props
    const { open } = this.state
    return (
      <div className="series-wrapper">
        <div className="shape-row">
          <div className="shape-col-1-np" />
          <div className="shape-col-10-np">
            <div>
              <Hero
                cols={12}
                tinyTitle="MULTIMEDIA"
                level={level}
                parent={1}
                title={[
                  {
                    text: `POSTALES ${serie.name}`,
                    color: 'purple',
                  },
                ]}
              />
              <div
                className={`${open ? 'open' : ''} toogle-btn`}
                onClick={() => this.setState({ open: !open })}
              />
            </div>
            <div className={`${open ? 'show' : 'hide'} list shape-row`}>
              {serie.postales.map(postal => (
                <Link to={url(URLS.MULTIMEDIA_POSTAL, postal.slug)}>
                  <div className="shape-col-6 shape-col-12-sm postal">
                    <figure>
                      <ImageWithPlaceHolder
                        alt={`${postal.titulo}`}
                        src={postal.imagen}
                      />
                    </figure>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className="shape-col-1-np" />
        </div>
      </div>
    )
  }
}

export default PostalesListWrapper
