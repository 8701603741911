import { applyMiddleware, createStore, combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import {
  createResourcesActions,
  createResourcesReducers,
  createResourcesSagas,
} from './resoursesUtils'
import { ITipoDeCasoState } from './tiposDeCaso/types'

import getConfig from '../config'
import API from '../api'
import { Auth } from '../auth'
import { ICasoInitialState } from './casos/types'
import moment from 'moment'
import { LOCATIONS } from '../constants'
import { IDefensoraInitialState } from './defensoras/types'
import { IMultimedia } from './Multimedia/types'
import { IAlertInitialState } from './alerts/types'

interface commonState<T> {
  isFetching: boolean
  isUpdating: boolean
  isCreating: boolean
  isDeleting: boolean
  hasFetched: boolean
  APIError?: string
  code: number
  items: Array<T>
}

const auth = new Auth(getConfig())

export const api = new API(auth)

export type AppState = {
  form
  tiposDeCasos: commonState<ITipoDeCasoState>
  caso: commonState<ICasoInitialState>
  casos: commonState<ICasoInitialState>
  feminicidios: commonState<ICasoInitialState>
  tentativas: commonState<ICasoInitialState>
  otrosCasos: commonState<ICasoInitialState>
  defensoras: commonState<IDefensoraInitialState>
  multimedia: commonState<IMultimedia>
  alerts: commonState<IAlertInitialState>
  contacto: commonState<{}>
}

const RESOURCES = [
  'tiposDeCasos',
  'casos',
  'caso',
  'feminicidios',
  'tentativas',
  'otrosCasos',
  'defensoras',
  'multimedia',
  'alerts',
  'contacto',
]

export const {
  tiposDeCasosActions,
  casosActions,
  casoActions,
  feminicidiosActions,
  tentativasActions,
  otrosCasosActions,
  defensorasActions,
  multimediaActions,
  alertsActions,
  contactoActions,
} = createResourcesActions(RESOURCES)

const casoFormat = x => {
  const ubicacionFeminicidio = LOCATIONS.find(
    l => l.value === x.feminicidio.lugar
  )
  const ubicacionNacimiento = LOCATIONS.find(
    l => l.value === x.victima.lugar_nacimiento
  )
  return {
    ...x,
    slug: x.slug,
    nombre: x.nombre,
    historia: x.historia.blocks,
    intro: x.intro,
    descripcion: x.descripcion,
    feminicidio: {
      ubicacion: ubicacionFeminicidio ? ubicacionFeminicidio.label : '',
      HASC: ubicacionFeminicidio ? 'MX.' + ubicacionFeminicidio.value : '',
      fecha: moment(x.feminicidio.fecha),
    },
    victima: {
      nombre: x.victima.nombre,
      ubicacion: ubicacionNacimiento ? ubicacionNacimiento.label : '',
      fecha: moment(x.victima.fecha_nacimiento),
    },
  }
}

const {
  tiposDeCasosReducer,
  casosReducer,
  casoReducer,
  feminicidiosReducer,
  tentativasReducer,
  otrosCasosReducer,
  defensorasReducer,
  multimediaReducer,
  alertsReducer,
  contactoReducer,
} = createResourcesReducers(RESOURCES, {
  tiposDeCasos: {
    formatItem: (x, key) => ({
      ...x,
      order:
        x.slug === 'feminicidio'
          ? 1
          : x.slug === 'tentativas-de-feminicidio'
          ? 2
          : x.slug === 'otros-casos-de-violencia'
          ? 3
          : key + 4,
    }),
  },
  casos: {
    formatItem: casoFormat,
  },
  caso: {
    formatItem: casoFormat,
  },
  feminicidios: {
    formatItem: casoFormat,
  },
  tentativas: {
    formatItem: casoFormat,
  },
  otrosCasos: {
    formatItem: casoFormat,
  },
  multimedia: {
    formatItem: x => x,
  },
  alerts: {
    formatItem: x => x,
  },
})

const state = combineReducers({
  form,
  tiposDeCasos: tiposDeCasosReducer,
  casos: casosReducer,
  caso: casoReducer,
  feminicidios: feminicidiosReducer,
  tentativas: tentativasReducer,
  otrosCasos: otrosCasosReducer,
  defensoras: defensorasReducer,
  multimedia: multimediaReducer,
  alerts: alertsReducer,
  contacto: contactoReducer,
})

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  state,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
)

createResourcesSagas(sagaMiddleware, api, RESOURCES)

export default store
