import React, { Component } from 'react'
import { RouterHistory, Location } from 'react-router-dom'

import { URLS } from '../../constants'
import Slider from './Slider'
import { Map } from '../../Components'

import './index.css'
import TextScrollAndData from './TextScrollAndData'
import Pleca from './Pleca'

type Props = {
  history: RouterHistory
  location: Location
}

type State = {
  tooltip: string
}

class Home extends Component<Props, State> {
  state = {
    tooltip: '',
  }

  componentDidMount() {
    const intro = localStorage.getItem('intro')
    if (intro !== 'skip') {
      this.props.history.push(URLS.INTRO)
    }
  }

  render() {
    return (
      <div className="block page home">
        <Slider changePage={url => this.props.history.push(url)} />
        <TextScrollAndData />
        <div className="map">
          <div className="shape-container">
            <div className="shape-full-with-block-map-title">
              <div className="shape-full-with-block-map-title-1">
                CASOS EMBLEMÁTICOS DE VIOLENCIA FEMINICIDA
              </div>
              <div className="shape-full-with-block-map-title-2">
                ACOMPAÑADOS POR OCNF
              </div>
            </div>
          </div>
          <Map noSelect />
        </div>

        <Pleca />
      </div>
    )
  }
}

export default Home
