import snakeCase from 'lodash.snakecase'
import { singular } from 'pluralize'

export function createResourceActions(resource) {
  const RESOURCE = snakeCase(resource).toUpperCase()

  const Resource = resource.replace(/./, RESOURCE[0])
  const Singular = singular(resource)

  const FETCH_RESOURCE = `FETCH_${RESOURCE}`
  const fetchResource = `fetch${Resource}`

  const FETCH_RESOURCE_REQUEST = `${FETCH_RESOURCE}_REQUEST`
  const fetchResourceRequest = `${fetchResource}Request`

  const FETCH_RESOURCE_SUCCESS = `${FETCH_RESOURCE}_SUCCESS`
  const fetchResourceSuccess = `${fetchResource}Success`

  const FETCH_RESOURCE_FAILURE = `${FETCH_RESOURCE}_FAILURE`
  const fetchResourceFailure = `${fetchResource}Failure`

  const CREATE_RESOURCE = `CREATE_${RESOURCE}`
  const createResource = `create${Resource}`

  const CREATE_RESOURCE_REQUEST = `${CREATE_RESOURCE}_REQUEST`
  const createResourceRequest = `${createResource}Request`

  const CREATE_RESOURCE_SUCCESS = `${CREATE_RESOURCE}_SUCCESS`
  const createResourceSuccess = `${createResource}Success`

  const CREATE_RESOURCE_FAILURE = `${CREATE_RESOURCE}_FAILURE`
  const createResourceFailure = `${createResource}Failure`

  const UPDATE_RESOURCE = `UPDATE_${RESOURCE}`
  const updateResource = `update${Resource}`

  const UPDATE_RESOURCE_REQUEST = `${UPDATE_RESOURCE}_REQUEST`
  const updateResourceRequest = `${updateResource}Request`

  const UPDATE_RESOURCE_SUCCESS = `${UPDATE_RESOURCE}_SUCCESS`
  const updateResourceSuccess = `${updateResource}Success`

  const UPDATE_RESOURCE_FAILURE = `${UPDATE_RESOURCE}_FAILURE`
  const updateResourceFailure = `${updateResource}Failure`

  const DELETE_RESOURCE = `DELETE_${RESOURCE}`
  const deleteResource = `delete${Resource}`

  const DELETE_RESOURCE_REQUEST = `${DELETE_RESOURCE}_REQUEST`
  const deleteResourceRequest = `${deleteResource}Request`

  const DELETE_RESOURCE_SUCCESS = `${DELETE_RESOURCE}_SUCCESS`
  const deleteResourceSuccess = `${deleteResource}Success`

  const DELETE_RESOURCE_FAILURE = `${DELETE_RESOURCE}_FAILURE`
  const deleteResourceFailure = `${deleteResource}Failure`

  const actions = {}

  actions[FETCH_RESOURCE] = FETCH_RESOURCE

  // The only function called from the views. It triggers the sagas
  actions.fetchResources = params => ({
    type: FETCH_RESOURCE,
    ...params,
  })

  actions[FETCH_RESOURCE_REQUEST] = FETCH_RESOURCE_REQUEST

  actions[fetchResourceRequest] = () => ({
    type: FETCH_RESOURCE_REQUEST,
  })

  actions[FETCH_RESOURCE_SUCCESS] = FETCH_RESOURCE_SUCCESS

  actions[fetchResourceSuccess] = response => {
    return {
      type: FETCH_RESOURCE_SUCCESS,
      items: response.items || [response],
      ...(response.links ? { links: response.links } : {}),
      ...(response.meta ? { meta: response.meta } : {}),
    }
  }

  actions[FETCH_RESOURCE_FAILURE] = FETCH_RESOURCE_FAILURE

  actions[fetchResourceFailure] = error => ({
    type: FETCH_RESOURCE_FAILURE,
    APIError: `Failed to fetch the ${resource}`,
    code: error.code,
  })

  actions[CREATE_RESOURCE] = CREATE_RESOURCE

  // The only function called from the views. It triggers the sagas
  actions.createResource = item => {
    return {
      type: CREATE_RESOURCE,
      item,
    }
  }

  actions[CREATE_RESOURCE_REQUEST] = CREATE_RESOURCE_REQUEST

  actions[createResourceRequest] = () => ({
    type: CREATE_RESOURCE_REQUEST,
  })

  actions[CREATE_RESOURCE_SUCCESS] = CREATE_RESOURCE_SUCCESS

  actions[createResourceSuccess] = item => ({
    type: CREATE_RESOURCE_SUCCESS,
    item,
  })

  actions[CREATE_RESOURCE_FAILURE] = CREATE_RESOURCE_FAILURE

  actions[createResourceFailure] = error => ({
    type: CREATE_RESOURCE_FAILURE,
    APIError: `Failed to create the ${Singular}`,
    code: error.code,
  })

  actions[UPDATE_RESOURCE] = UPDATE_RESOURCE

  // The only function called from the views. It triggers the sagas
  actions.updateResource = item => ({
    type: UPDATE_RESOURCE,
    item,
  })

  actions[UPDATE_RESOURCE_REQUEST] = UPDATE_RESOURCE_REQUEST

  actions[updateResourceRequest] = () => ({
    type: UPDATE_RESOURCE_REQUEST,
  })

  actions[UPDATE_RESOURCE_SUCCESS] = UPDATE_RESOURCE_SUCCESS

  actions[updateResourceSuccess] = item => ({
    type: UPDATE_RESOURCE_SUCCESS,
    item,
  })

  actions[UPDATE_RESOURCE_FAILURE] = UPDATE_RESOURCE_FAILURE

  actions[updateResourceFailure] = error => ({
    type: UPDATE_RESOURCE_FAILURE,
    APIError: `Failed to update the ${Singular}`,
    code: error.code,
  })

  actions[DELETE_RESOURCE] = DELETE_RESOURCE

  // The only function called from the views. It triggers the sagas
  actions.deleteResource = item => ({
    type: DELETE_RESOURCE,
    item,
  })

  actions[DELETE_RESOURCE_REQUEST] = DELETE_RESOURCE_REQUEST

  actions[deleteResourceRequest] = () => ({
    type: DELETE_RESOURCE_REQUEST,
  })

  actions[DELETE_RESOURCE_SUCCESS] = DELETE_RESOURCE_SUCCESS

  actions[deleteResourceSuccess] = item => ({
    type: DELETE_RESOURCE_SUCCESS,
    item,
  })

  actions[DELETE_RESOURCE_FAILURE] = DELETE_RESOURCE_FAILURE

  actions[deleteResourceFailure] = error => ({
    type: DELETE_RESOURCE_FAILURE,
    APIError: `Failed to delete the ${Singular}`,
    code: error.code,
  })

  return actions
}
