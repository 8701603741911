import React from 'react'
import Row from './Row'

const Template = ({ data }) => {
  return (
    <>
      {data.map((d, key) => {
        if (d.type === 'row') {
          return <Row key={key} data={d.data} level={key} />
        }
        return null
      })}
    </>
  )
}

export default Template
