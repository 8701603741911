import React from 'react'
import { ComposableMap, Geographies, Geography } from 'react-simple-maps'

const geoUrl =
  'https://raw.githubusercontent.com/Ragoli/maps/master/gadm36_MEX_1.json'

const MapChart = ({ mapData, selectCountry }) => {
  return (
    <ComposableMap
      projection="geoAlbers"
      projectionConfig={{
        scale: 1550,
        center: [-6, 24],
      }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo, key) => {
            const d = mapData.find(s => s.HASC === geo.properties.HASC_1)

            return (
              <Geography
                key={key}
                geography={geo}
                fill={d ? d.color : '#d2d2d2'}
                stroke="rgba(255, 255, 255, .5)"
                onClick={() => selectCountry(geo.properties.HASC_1)}
              />
            )
          })
        }
      </Geographies>
    </ComposableMap>
  )
}

export default MapChart
