import React from 'react'
import { logoLg } from '../../Images'

import './index.css'

const Loading = () => (
  <div className="shape-loading">
    <div className="shape-loading-center">
      <div className="center">
        Cargando Datos
        <img src={logoLg} alt="nos van a ver juntas" />
      </div>
    </div>
  </div>
)

export default Loading
