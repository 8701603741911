import { WrappedFieldProps } from 'redux-form'

import React from 'react'
import { Field } from 'redux-form'

import './index.css'

export type Option = {
  value: string
  label: string
  color: string
}

type Props = {
  options: Option[]
} & React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps

export const renderRadioField = (props: Props) => {
  if (!props || !props.input || !props.options) return <div />

  const renderRadioButtons = props.options.map(option => {
    const color = option.color
    const activeColor =
      props.input.value === option.value ? { backgroundColor: color } : {}

    const styles = {
      border: `3px solid ${color}`,
      ...activeColor,
    }
    return (
      <div
        key={option.value}
        className={` shape-radioSelector-option ${
          props.input.value === option.value ? 'active' : ''
        }`}
      >
        <Field
          id={option.value}
          component="input"
          name={props.input.name}
          type="radio"
          value={option.value}
          checked={props.input.value === option.value}
        />
        <span className="shape-radio-group" style={styles} />

        <label className="shape-radio-label">{option.label}</label>
      </div>
    )
  })

  return <div className="shape-radioSelector">{renderRadioButtons}</div>
}

export default renderRadioField
