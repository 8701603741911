import React from 'react'
import SoundCloudFrame from '../SoundCloudFrame'

import './index.css'

const AudioPlayer = ({ relacion, name, url }) => {
  return (
    <div className="shape-audio-container">
      <div className="shape-audio-title">
        {`Audio ${relacion} de `}
        <span className="text-color-violet">{name}</span>
      </div>
      <SoundCloudFrame url={url} />
    </div>
  )
}

export default AudioPlayer
