import React from 'react'
import { Link } from 'react-router-dom'
import { kebabCase } from 'lodash'

import { URLS } from '../../constants'
import { url } from '../../utils'
import { ImageWithPlaceHolder, Button } from '../../Components'

const DefensoraCard = ({ defender }) => {
  const color =
    defender.tipo_familiar === 'Tentativa de feminicidio' ? 'orange' : 'violet'

  return (
    <div className="shape-defensor-card">
      <div className="shape-defensor-card-left">
        <figure>
          <ImageWithPlaceHolder
            alt={`defensora-${defender.slug}`}
            src={defender.foto}
            defensora="true"
          />
        </figure>
      </div>
      <div className="shape-defensor-card-right">
        <div className={`shape-defensor-title text-color-${color}`}>
          {defender.nombre}
        </div>
        {defender.casos_id &&
        defender.casos_id.length &&
        defender.tipo_familiar !== 'Tentativa de feminicidio' ? (
          <div className="shape-defensor-info">
            {`${defender.tipo_familiar} de `}
            <Link
              className={`text-color-${color}`}
              to={url(
                URLS.CASE,
                kebabCase(defender.casos_id[0].tipoCaso_id.nombre),
                defender.casos_id[0].slug
              )}
            >
              {defender.casos_id[0].titulo}
            </Link>
          </div>
        ) : (
          <div className="shape-defensor-info">
            <Link
              className={`text-color-${color}`}
              to={url(
                URLS.CASE,
                kebabCase(defender.casos_id[0].tipoCaso_id.nombre),
                defender.casos_id[0].slug
              )}
            >
              Caso
            </Link>
            {` de ${defender.tipo_familiar}`}
          </div>
        )}

        <Link to={url(URLS.DEFENDER, defender.slug)}>
          <Button color="black" outline size="md" text="Ver Más" />
        </Link>
      </div>
    </div>
  )
}

export default DefensoraCard
