import React, { PureComponent } from 'react'

import './index.css'

type Props = {
  onRequestClose: () => void
}

class Modal extends PureComponent<Props> {
  render() {
    const { onRequestClose } = this.props

    return (
      <section className="shape-modal-wrapper">
        <div className="shape-modal-center">
          <div className="shape-modal-card">
            <div
              onClick={onRequestClose}
              className="shape-modal-wrapper-close"
            ></div>
            <div className="shape-modal-card-container">
              {this.props.children}
            </div>
          </div>
          <div className="close-overlay-top" onClick={onRequestClose} />
        </div>
      </section>
    )
  }
}

export default Modal
