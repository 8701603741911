import React from 'react'
import './index.css'

type Props = {
  parent?: number
  level?: number
  cols: number
  tinyTitle: string
  title: {
    text: string
    color?: string
  }[]
  subtitle?: {
    text: string
    color?: string
  }[]
  color?: string
}

const Hero = (data: Props) => {
  return (
    <div
      className={`shape-col-${
        data.cols
      }-np shape-text-hero level-${data.parent || 0}-${data.level || 0}`}
    >
      {data.tinyTitle ? (
        <div className="shape-text-hero-tinyTitle">{data.tinyTitle} </div>
      ) : null}
      {data.title ? (
        <div className="shape-text-hero-title">
          {data.title.map((t, key) => (
            <div key={key} className={`text-color-${t.color || 'black'}`}>
              {t.text}
            </div>
          ))}
        </div>
      ) : null}
      {data.subtitle ? (
        <div className="shape-text-hero-subtitle">
          {data.subtitle.map((t, key) => (
            <div key={key} className={`text-color-${t.color || 'black'}`}>
              {t.text}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}
export default Hero
