const HOME = '/'
const CAMPAIGN = '/sobre_la_campana'
const CASES = '/casos'
const CASE = '/caso/:typeSlug/:caseSlug'
const FROM_VICTIMS_TO_DEFENDERS = '/defensoras'
const DEFENDER = '/:slug'
const MULTIMEDIA = '/multimedia'
const VIDEO = '/video/:slug'
const POSTAL = '/postal/:slug'
const INFOGRAFIA = '/infografia/:slug'
const CONTACT = '/contacto'
const INTRO = '/intro'

export const URLS = Object.freeze({
  /* prettier-ignore */
  HOME:                               HOME,
  /* prettier-ignore */
  CAMPAIGN:                           CAMPAIGN,
  /* prettier-ignore */
  CASES:                              CASES,
  /* prettier-ignore */
  CASE:                               CASE,
  /* prettier-ignore */
  FROM_VICTIMS_TO_DEFENDERS:          FROM_VICTIMS_TO_DEFENDERS,
  /* prettier-ignore */
  DEFENDER:                           FROM_VICTIMS_TO_DEFENDERS + DEFENDER,
  /* prettier-ignore */
  MULTIMEDIA:                         MULTIMEDIA,
  /* prettier-ignore */
  MULTIMEDIA_VIDEO:                  MULTIMEDIA + VIDEO,
  /* prettier-ignore */
  MULTIMEDIA_POSTAL:                  MULTIMEDIA + POSTAL,
  /* prettier-ignore */
  MULTIMEDIA_INFOGRAFIA:             MULTIMEDIA + INFOGRAFIA,
  /* prettier-ignore */
  CONTACT:                            CONTACT,
  /* prettier-ignore */
  INTRO:                              INTRO
})

export const PAGES_IDS = {
  HOME: {
    id: 'home',
    label: 'Home',
    type: 'logo',
    path: URLS.HOME,
  },
  CAMPAIGN: {
    id: 'sobre_la_campana',
    label: 'Sobre la Campaña',
    path: URLS.CAMPAIGN,
    type: 'link',
  },
  CASES: { id: 'casos', label: 'Casos', path: URLS.CASES, type: 'link' },
  FROM_VICTIMS_TO_DEFENDERS: {
    id: 'defensoras',
    label: 'De Víctimas a Defensoras',
    path: URLS.FROM_VICTIMS_TO_DEFENDERS,
    type: 'link',
  },
  MULTIMEDIA: {
    id: 'multimedia',
    label: 'Multimedia',
    path: URLS.MULTIMEDIA,
    type: 'link',
  },
  CONTACT: {
    id: 'contact',
    label: 'Contacto',
    path: URLS.CONTACT,
    type: 'link',
  },
}

export const ENTRIES = Object.entries(PAGES_IDS).map(e => e[1])

export const LOCATIONS = [
  {
    label: 'Aguascalientes',
    value: 'AG',
  },
  {
    label: 'Baja California',
    value: 'BN',
  },
  {
    label: 'Baja California Sur',
    value: 'BS',
  },
  { label: 'Campeche', value: 'CM' },
  { label: 'Chiapas', value: 'CP' },
  { label: 'Chihuahua', value: 'CH' },
  {
    label: 'Ciudad de México',
    value: 'DF',
  },
  { label: 'Coahuila', value: 'CA' },
  { label: 'Colima', value: 'CL' },
  { label: 'Durango', value: 'DU' },
  { label: 'Guanajuato', value: 'GJ' },
  { label: 'Guerrero', value: 'GR' },
  { label: 'Hidalgo', value: 'HI' },
  { label: 'Jalisco', value: 'JA' },
  { label: 'Estado de México', value: 'MX' },
  { label: 'Michoacán', value: 'MC' },
  { label: 'Morelos', value: 'MR' },
  { label: 'Nayarit', value: 'NA' },
  {
    label: 'Nuevo León',
    value: 'NL',
  },
  { label: 'Oaxaca', value: 'OA' },
  { label: 'Puebla', value: 'PU' },
  { label: 'Querétaro', value: 'QE' },
  {
    label: 'Quintana Roo',
    value: 'QR',
  },
  {
    label: 'San Luis Potosí',
    value: 'SL',
  },
  { label: 'Sinaloa', value: 'SI' },
  { label: 'Sonora', value: 'SO' },
  { label: 'Tabasco', value: 'TB' },
  { label: 'Tamaulipas', value: 'TM' },
  { label: 'Tlaxcala', value: 'TL' },
  { label: 'Veracruz', value: 'VE' },
  { label: 'Yucatán', value: 'YU' },
  { label: 'Zacatecas', value: 'ZA' },
]

export const MEDIA = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
}

export const MEDIA_BUTTON_TEXT = [
  {
    value: 1,
    label: 'Ayúdanos con un tweet',
  },
  {
    value: 2,
    label: 'Ayúdanos con un "Me gusta" en Facebook',
  },
  {
    value: 3,
    label: 'Ayúdanos compartiendo',
  },
]

export const TIPOS_DE_CASO = {
  FEMINICIDIO: {
    id: 0,
    slug: 'feminicidio',
    color: 'violet',
    name: 'Feminicidio',
  },
  TENTATIVAS_DE_FEMINICIDIO: {
    id: 1,
    slug: 'tentativas-de-feminicidio',
    color: 'orange',
    name: 'Tentativa de feminicidio',
  },
  OTROS_CASOS_DE_VIOLENCIA: {
    id: 2,
    slug: 'otros-casos-de-violencia',
    color: 'orange',
    name: 'Otros Casos de Violencia',
  },
}

export const TIPOS_DE_CASO_OPTIONS = Object.entries(TIPOS_DE_CASO).map(
  t => t[1]
)
