import { FetchWrapper } from './FetchWrapper'

import getConfig from '../config'
import admin from './admin'

const IFetchWrapper = FetchWrapper

const APIMixins = (Base: typeof IFetchWrapper) => admin(Base)

export default class API extends APIMixins(FetchWrapper) {
  constructor(auth: any) {
    const BASE_URL = `${getConfig().API_URL}`
    // @ts-ignore
    super(auth, BASE_URL)
  }
}
