import { createResourceReducer } from './createResourceReducer'

export function createResourcesReducers(resources, options) {
  return resources.reduce(function(reducers, resource) {
    reducers[resource + 'Reducer'] =
      options && options[resource]
        ? createResourceReducer(resource, options[resource])
        : createResourceReducer(resource)
    return reducers
  }, {})
}
