import type { Location } from 'react-router-dom'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import React from 'react'
import { withRouter } from 'react-router-dom'
import { AppState, alertsActions } from '../state'
import { isEqual } from 'lodash';
import Loading from './Loading';
import { MEDIA_BUTTON_TEXT } from '../constants';

type OwnProps = {
  location: Location,
}


type StateProps = ReturnType<typeof mapStateToProps>
type ActionsProps = typeof mapActionsToProps

type Props = OwnProps & StateProps & ActionsProps

const Toast =({ text, link, titulo, buttonTest }: {link:string, text:string, titulo:string, buttonTest:string}) => {
  return (
  <div className='shape-toast'>
    <div className='title text-color-violet'>{titulo}</div>
    <p className='text'>{text}</p>
    <a href={link} rel="noopener noreferrer" target="_blank">{buttonTest}</a>
  </div>
)}

// https://reacttraining.com/react-router/web/guides/scroll-restoration

type State = {
  loading:boolean
}
class ScrollToTop extends React.Component<Props, State> {
  interval?: number

  state =  {
    loading:true
  }

  
  componentDidMount() {
    this.clearInterval()

    if(this.props.location.pathname !== '/intro' ){
      this.props.fetchAlerts()
      if(!this.props.location.pathname.includes('/contacto')){
        this.setInterval()
      }else{
        this.setState(() => ({
          loading: false,
        }))
      }
    }
    
  }

  componentDidUpdate(prevProps, prevState) {
    const {alerts, hasFetchedAlerts} = this.props
    const {loading} = this.state

    if(!loading && prevState.loading) {
      this.clearInterval()
    }

    

    if (this.props.location !== prevProps.location  ) {

      this.clearInterval()

      if(!this.props.location.pathname.includes('/defensoras') && !this.props.location.pathname.includes('/multimedia') ){
        window.scrollTo(0, 0)
        let r = document.getElementById('body')
      r && r.classList.remove('blockScroll')
       
      }  

      if(!this.props.location.pathname.includes('/contacto')){
        this.setInterval()
      }else{
        this.setState(() => ({
          loading: false,
        }))
      }

      if(!alerts.length &&  !hasFetchedAlerts){
        this.props.fetchAlerts()
      }
    }

    if( alerts.length &&  !isEqual(prevProps.alerts,alerts)){
     
      alerts.map( alert => 
        {
          const buttonTest = MEDIA_BUTTON_TEXT.find( media => media.value === alert.tipo)
          return toast(<Toast link={alert.link} text={alert.text} titulo={alert.titulo} buttonTest={buttonTest?.label || 'Ayúdanos compartiendo'}/> , {className: 'toastify-container',
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,})
      }
        )
    }

    

  }

  
  setInterval = () => {
    this.interval = window.setInterval(() => {
      this.setState(() => ({
        loading: false,
      }))
    }, 2000)
  }

  clearInterval = () => {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  render() {
    const {isFetching} = this.props
    const {loading} = this.state
    return <>
     {isFetching && this.props.location.pathname !== '/intro'|| loading && this.props.location.pathname !== '/intro' ? <Loading /> : null}
      {this.props.location.pathname !== '/intro' ?<ToastContainer
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      /> :null}
    {this.props.children}
    </>
  }
}

const mapStateToProps = (state:AppState) => {
  return {
    alerts:state.alerts.items,
    hasFetchedAlerts:state.alerts.hasFetched,
    isFetching:state.alerts.isFetching|| state.casos.isFetching || state.defensoras.isFetching || state.caso.isFetching
  }
}

const mapActionsToProps = {
  fetchAlerts: alertsActions.fetchResources,
  
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(ScrollToTop))
