import React, { Fragment } from 'react'
import ReactPlayer from 'react-player'
import './index.css'

type Props = {
  parent?: number
  level?: number
  cols: number
  className?: string
  text: {
    type: string
    text?: string
    color?: string
    link?: string
    items?: string[]
  }[]

  color?: string
}

const BodyText = (data: Props) => {
  const insideP = ['p', 'strong', 'mark', 'link', 'br', 'video']

  return (
    <div
      className={`shape-col-${data.cols}-np shape-col-12-sm  ${data.className ||
        ''} shape-text-body level-${data.parent || 0}-${data.level || 0}`}
    >
      <p>
        {data.text.map((t, key) => {
          if (insideP.includes(t.type)) {
            return (
              <Fragment key={key}>
                {t.type === 'p' ? (
                  <span className={t.color ? `text-color-${t.color}` : ''}>
                    {t.text}
                  </span>
                ) : null}

                {t.type === 'strong' ? (
                  <strong className={t.color ? `text-color-${t.color}` : ''}>
                    {t.text}
                  </strong>
                ) : null}

                {t.type === 'mark' ? (
                  <mark
                    className={t.color ? `bg-color-${t.color}-opacity` : ''}
                  >
                    {t.text}
                  </mark>
                ) : null}
                {t.type === 'link' && t.link ? (
                  <a
                    target="_blank"
                    className={t.color ? `text-color-${t.color}` : ''}
                    href={t.link}
                    rel="noopener noreferrer"
                  >
                    {t.text}
                  </a>
                ) : null}

                {t.type === 'video' && t.link ? (
                  <ReactPlayer url={t.link} width="100%" />
                ) : null}

                {t.type === 'br' ? <br /> : null}
              </Fragment>
            )
          }
          if (t.type === 'hr') {
            return (
              <span
                key={key}
                className={(t.color ? `bg-color-${t.color}` : '') + ' hr'}
              />
            )
          }
          return null
        })}
      </p>

      {data.text
        .filter(t => t.type === 'ol')
        .map((list, key) => {
          return (
            <ul key={key}>
              {list.items && list.items.map((li, k) => <li key={key}>{li}</li>)}
            </ul>
          )
        })}
    </div>
  )
}

export default BodyText
