import React, { Component } from 'react'

import './index.css'
import ScrollText from './ScrollText'

type Props = {}

class TextScrollAndData extends Component<Props> {
  render() {
    return (
      <div className="shape-full-with-block paddingAndMarging">
        <ScrollText />
        <div className="shape-container data-container">
          <div className="shape-row">
            <div className="shape-col-3 shape-col-6-md shape-col-12-sm box">
              <div className="shape-full-with-block-title">CIFRAS</div>
              <div className="shape-full-with-block-type">GENERALES</div>
              <div className="shape-full-with-block-date">2019</div>
            </div>
            <div className="shape-col-3 shape-col-6-md shape-col-12-sm box">
              <div className="shape-full-with-block-title">3,825</div>
              <div className="shape-full-with-block-type">MUJERES</div>
              <div className="shape-full-with-block-copy">
                FUERON ASESINADAS <br />
                EN 2019
              </div>
            </div>
            <div className="shape-col-3 shape-col-6-md shape-col-12-sm box">
              <div className="shape-full-with-block-title">+1,006</div>
              <div className="shape-full-with-block-type">CASOS</div>
              <div className="shape-full-with-block-copy">
                INVESTIGADOS <br />
                COMO FEMINICIDIO
              </div>
            </div>
            <div className="shape-col-3 shape-col-6-md shape-col-12-sm box">
              <div className="shape-full-with-block-title">10</div>
              <div className="shape-full-with-block-type">MUJERES</div>
              <div className="shape-full-with-block-copy">
                ASESINADAS <br />
                AL DÍA*
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TextScrollAndData
