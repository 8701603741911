import React from 'react'
import ImageWithPlaceHolder from '../../Image'
import './index.css'

type Props = {
  parent: number
  level: number
  cols: number
  className?: string
  img: string
  color?: string
  figcaption?: string
}

const Image = (data: Props) => {
  return (
    <figure className={`shape-col-${data.cols}-np shape-text-img`}>
      <ImageWithPlaceHolder alt="nos van a ver juntas" src={data.img} />
      {data.figcaption ? <figcaption>{data.figcaption}</figcaption> : null}
    </figure>
  )
}
export default Image
