import snakeCase from 'lodash.snakecase'

import { resourceInitialState } from './resourceInitialState'

export function createResourceReducer(resource, options) {
  const RESOURCE = snakeCase(resource).toUpperCase()
  const initialState = (options && options.initialState) || resourceInitialState

  return (state = initialState, action) => {
    switch (action.type) {
      case `FETCH_${RESOURCE}_REQUEST`:
        return {
          ...state,
          isFetching: true,
          hasFetched: false,
          APIError: undefined,
          code: undefined,
        }
      case `FETCH_${RESOURCE}_SUCCESS`:
        if (!action.items) return state

        const items =
          options && options.formatItem
            ? action.items.map(options.formatItem)
            : action.items

        return {
          ...state,
          isFetching: false,
          hasFetched: true,
          items:
            options && options.concatenateFetchedItems
              ? [...state.items, ...items]
              : items,
          links: action.links,
          meta: action.meta,
        }
      case `FETCH_${RESOURCE}_FAILURE`:
        return {
          ...state,
          isFetching: false,
          hasFetched: true,
          ...(action.code !== 401 ? { APIError: action.APIError } : {}),
          ...(action.code ? { code: action.code } : {}),
        }
      case `CREATE_${RESOURCE}_REQUEST`:
        return {
          ...state,
          isCreating: true,
          APIError: undefined,
          code: undefined,
        }
      case `CREATE_${RESOURCE}_SUCCESS`:
        if (!action.item) return state

        const newItem =
          options && options.formatItem
            ? options.formatItem(action.item)
            : action.item

        return {
          ...state,
          isCreating: false,
          items: [...state.items, newItem],
        }
      case `CREATE_${RESOURCE}_FAILURE`:
        return {
          ...state,
          isCreating: false,
          ...(action.code !== 401 ? { APIError: action.APIError } : {}),
          ...(action.code ? { code: action.code } : {}),
        }
      case `UPDATE_${RESOURCE}_REQUEST`:
        return {
          ...state,
          isUpdating: true,
          APIError: undefined,
          code: undefined,
        }
      case `UPDATE_${RESOURCE}_SUCCESS`:
        if (!action.item) return state

        const updatedItem =
          options && options.formatItem
            ? options.formatItem(action.item)
            : action.item

        return {
          ...state,
          isUpdating: false,

          items: state.items.map(item =>
            item.id === updatedItem.id ? updatedItem : item
          ),
        }
      case `UPDATE_${RESOURCE}_FAILURE`:
        return {
          ...state,
          isUpdating: false,
          ...(action.code !== 401 ? { APIError: action.APIError } : {}),
          ...(action.code ? { code: action.code } : {}),
        }
      case `DELETE_${RESOURCE}_REQUEST`:
        return {
          ...state,
          isDeleting: true,
          APIError: undefined,
          code: undefined,
        }
      case `DELETE_${RESOURCE}_SUCCESS`:
        if (!action.item) return state

        const deletedItem = action.item

        return {
          ...state,
          isDeleting: false,
          items: state.items.filter(item => item.slug !== deletedItem),
        }
      case `DELETE_${RESOURCE}_FAILURE`:
        return {
          ...state,
          isDeleting: false,
          ...(action.code !== 401 ? { APIError: action.APIError } : {}),
          ...(action.code ? { code: action.code } : {}),
        }
      default:
        return state
    }
  }
}
