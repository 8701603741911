import React, { PureComponent } from 'react'

import './index.css'
import { MediaIconSVG } from '..'
import { MEDIA } from '../../constants'
import { campaignLogoPartner3, campaignLogoPartner2 } from '../../Images'

type Props = {}

class Footer extends PureComponent<Props> {
  render() {
    return (
      <footer className="shape-footer">
        <div className="shape-row">
          <div className="shape-col-4 shape-col-12-md">
            <div className="media-row">
              <div className="media-icon">
                <MediaIconSVG type={MEDIA.FACEBOOK} />
              </div>
              <div className="media-link">
                <a
                  href="https://www.facebook.com/ocnfeminicidio.mexico"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>@OCNFeminicidio México</span>
                </a>
              </div>
            </div>

            <div className="media-row">
              <div className="media-icon">
                <MediaIconSVG type={MEDIA.INSTAGRAM} />
              </div>
              <div className="media-link">
                <a
                  href="https://www.instagram.com/ocnfeminicidiomexico/?hl=es-la"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>@OCNFeminicidioMéxico</span>
                </a>
              </div>
            </div>
            <div className="media-row">
              <div className="media-icon">
                <MediaIconSVG type={MEDIA.TWITTER} />
              </div>
              <div className="media-link">
                <a
                  href="https://twitter.com/OCNF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>@OCNF</span>
                </a>
              </div>
            </div>

            <div className="media-row">
              <div className="media-icon">
                <MediaIconSVG type={MEDIA.YOUTUBE} />
              </div>
              <div className="media-link">
                <a
                  href="https://www.youtube.com/channel/UCpWpvc6tiIlj9GzB_z4t1ig"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Observatorio Feminicidio</span>
                </a>
              </div>
            </div>
          </div>
          <div className="shape-col-4 shape-col-12-md">
            <div className="media-row link media-link">
              <a
                href="mailto:ocnf@observatoriofeminicidiomexico.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>ocnf@observatoriofeminicidiomexico.org</span>
              </a>
            </div>

            <div className="media-row link media-link">
              <a
                href="www.observatoriofeminicidiomexico.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>www.observatoriofeminicidiomexico.org</span>
              </a>
            </div>
            <div className="media-row text ">
              <strong>Observatorio Ciudadano Nacional de Feminicidio </strong>
              2020. MÉXICO.
            </div>
          </div>
          <div className="shape-col-4 shape-col-12-md">
            <img
              className="partner"
              loading="lazy"
              width="100%"
              src={campaignLogoPartner3}
              alt="nosvanaverjutas-partner"
            />
            <img
              className="partner"
              loading="lazy"
              width="100%"
              src={campaignLogoPartner2}
              alt="nosvanaverjutas-partner"
            />
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
