import React, { PureComponent } from 'react'
import { Link, Route } from 'react-router-dom'
import { PAGES_IDS, ENTRIES } from '../../constants'
import { logoLg } from '../../Images'

import './index.css'

type Entry = {
  id: string
  label: string
  path?: string
  type: string
}

type Props = {
  blockScroll: (string) => void
}

type State = {
  open: boolean
}

class Header extends PureComponent<Props, State> {
  state = {
    open: false,
  }

  toggleMenu = () => {
    this.setState(prevState => ({ open: !prevState.open }))
  }

  render() {
    const { open } = this.state

    return (
      <Route
        render={props => {
          const { pathname } = props.location
          const current = pathname.replace('/', '')

          return (
            <nav
              className={`shape-header ${
                current.includes('defensoras/') ? 'blockScroll' : ''
              }`}
            >
              <ul className={`${open ? 'open' : ''}`}>
                {current !== 'intro'
                  ? ENTRIES.map((i: Entry) => {
                      const active = current === i.id ? 'active ' : ''

                      return (
                        <li key={i.id} className={i.type}>
                          <Link
                            key={i.id}
                            to={i.path}
                            className={`${active} shape-sidebar-page`}
                            onClick={() =>
                              this.setState(prevState => ({
                                open: false,
                              }))
                            }
                          >
                            {i.path === PAGES_IDS.HOME.path ? (
                              <img src={logoLg} alt="nos van a ver juntas" />
                            ) : (
                              i.label
                            )}
                          </Link>
                        </li>
                      )
                    })
                  : null}
              </ul>
              <div className="extra-buttons">
                <div
                  className={`menu-btn ${open ? 'open' : ''} `}
                  onClick={this.toggleMenu}
                >
                  <span className="menu-btn-lines"></span>
                </div>
              </div>
            </nav>
          )
        }}
      />
    )
  }
}

export default Header
