import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../../Components'

import './index.css'
import { URLS } from '../../../constants'

type Props = {}

class Pleca extends Component<Props> {
  render() {
    return (
      <div className="shape-pleca">
        <div className="shape-container">
          <div className="shape-row">
            <div className="shape-col-6 shape-col-12-md">
              <div className="shape-pleca-tiny-title">Casos</div>
              <div className="shape-pleca-title">
                CASOS <br /> EMBLEMÁTICOS
              </div>
            </div>
            <div className="shape-col-6 shape-col-12-md">
              <div className="shape-pleca-text">
                Conoce sus casos, proyectos, <br />
                triunfos, anhelos y sueños;
                <br />
                <strong>
                  su ausencia vive en nuestra <br /> memoria todos los días.
                </strong>
              </div>
              <div className="shape-pleca-copy">
                Casos emblemáticos y mapa de Feminicidios <br /> y Tentativa de
                feminicidio en México.
                <div>
                  <Link to={URLS.CASES}>
                    <Button color="black" text="Ver más" size="md"></Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Pleca
