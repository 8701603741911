import type { Location } from 'react-router-dom'

import React, { PureComponent } from 'react'
import { RouterHistory } from 'react-router-dom'
import MetaTags from 'react-meta-tags'

import './index.css'
import { Button } from '../../Components'
import { logoLg } from '../../Images'

import Controls from './Controls'

type Props = { history: RouterHistory
  location: Location, }
type State = any

const PAGES_INTRO = [
  {
    id: 0,
    seconds: 4,
  },
  {
    id: 1,
    title: 'CONTEXTO',
    copy:
      'En México, cada día son asesinadas 10 mujeres. Se ha declarado Alerta de  Violencia de Género en 59% del territorio nacional.',
    text:
      'Ser víctima de feminicidio en nuestro país significa no sólo  perder la vida sino la identidad, para convertirse en un expediente más entre miles. ',
    seconds: 8,
  },
  {
    id: 2,
    title: 'SOBRE LA CAMPAÑA',
    copy:
      'Es prioridad reivindicar el valor de las mujeres asesinadas a partir de la reconstrucción de su memoria.',
    text:
      'Gracias a Católicas por el Derecho a Decidir (CDD) y al Observatorio Ciudadano Nacional de Feminicidio (OCNF) surge la campaña#NOSVANAVERJUNTAS',
    seconds: 8,
  },

  {
    id: 3,
    title: 'LLAMADO A LA ACCIÓN',
    copy:
      'Una campaña que levanta la voz sin miedo y nos recuerda que la memoria y la esperanza viven en nosotras.',
    text:
      'Agradecemos a todas personas que hicieron posible este proyecto, y a tí por tomar acción y formar parte de una comunidad que contribuye a erradicar la violencia feminicida en México.',
    seconds: 8,
  },
]
class Intro extends PureComponent<Props, State> {
  interval?: number

  state = {
    page: 0,
  }

  componentWillMount() {
    this.clearInterval()

  }

  componentDidMount() {

    localStorage.setItem('intro', '')
    this.setInterval()
  }

  setInterval = () => {
    const { page } = this.state
    const current = PAGES_INTRO.find(p => p.id === page) || { seconds: 1 }
    this.interval = window.setInterval(() => {
      this.onNextClick()
    }, current.seconds * 1000)
  }

  clearInterval = () => {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  goTopage(page) {
    this.clearInterval()
    this.setState({ page }, () => this.setInterval())
  }

  onNextClick() {
    this.clearInterval()
    if (this.state.page < PAGES_INTRO.length - 1) {
      this.setState({ page: this.state.page + 1 }, () => this.setInterval())
    } else {
      this.changePage()
    }
  }

  onPrevClick() {
    this.clearInterval()
    if (this.state.page > 0) {
      this.setState({ page: this.state.page - 1 }, () => this.setInterval())
    } else {
      return
    }
  }

  changePage() {
    localStorage.setItem('intro', 'skip')

    this.props.history.push('/')
  }

  render() {
    const { page } = this.state

    return (
      <>
      <MetaTags>
          <title>#NOSVANAVERJUNTAS</title>

          <meta
            property="og:title"
            content="#NOSVANAVERJUNTAS"
          />
          <meta
            property="og:description"
            content='En México, cada día son asesinadas 10 mujeres. Se ha declarado Alerta de  Violencia de Género en 59% del territorio nacional.'
          />

          <meta
            name="twitter:card"
            content="#NOSVANAVERJUNTAS"
          />

          <meta
            property="og:site_name"
            content="#NOSVANAVERJUNTAS"
          />
          <meta
            name="twitter:image:alt"
            content="#NOSVANAVERJUNTAS"
          />

          <meta property="fb:app_id" content="ocnfeminicidio.mexico" />
          <meta name="twitter:site" content="@OCNF" />
        </MetaTags>

      <div className="intro">
        <div className="container">
          <ol className="slide-container">
            <div className="header-logo">
              <img src={logoLg} alt="nos van a ver juntas" />
            </div>
            <Controls
              onPrevClick={() => this.onPrevClick}
              onNextClick={() => this.onNextClick}
              pages={PAGES_INTRO.map(p => p.id)}
              goTopage={exactPage => this.goTopage(exactPage)}
              activePage={page}
            />

            {PAGES_INTRO.map(item => {
              let computedClass = item.id === page ? 'slide active' : 'slide'

              return (
                <li
                  className={`${item.title ? '' : 'logo'} ${computedClass}`}
                  key={item.id}
                >
                  <div className="slide-intro">
                    {item.title ? (
                      <div className="slide-center">
                        <div className="title">{item.title}</div>
                        <div className="copy">{item.copy}</div>
                        <div className="text">{item.text}</div>
                        <Button
                          text="Entrar"
                          color="primary"
                          size="md"
                          onClick={() => this.changePage()}
                        />
                      </div>
                    ) : (
                      <img src={logoLg} alt="logo" className="logo" />
                    )}
                  </div>
                </li>
              )
            })}
          </ol>
        </div>
      </div>
      </>
    )
  }
}
export default Intro
