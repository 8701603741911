import type { Location } from 'react-router-dom'

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { orderBy, groupBy } from 'lodash'

import { Helmet } from 'react-helmet'

import { Map, Hero, BodyText } from '../../Components'

import { AppState } from '../../state'
import { formValueSelector } from 'redux-form'
import VictimCard from './VictimCard'

import './index.css'

type OwnProps = {
  location: Location,
}

type StateProps = ReturnType<typeof mapStateToProps>

type Props = StateProps & OwnProps

class Cases extends PureComponent<Props> {

  render() {
    const { casosForList, selectedTipoDeCasoObj } = this.props

    return (
      <>
       <Helmet>
            <title>CASOS ACOMPAÑADOS POR OCNF</title>

            <meta property="og:title" content='CASOS ACOMPAÑADOS POR OCNF' />
            <meta property="og:description" content='Es imprescindible reivindicar el valor de las mujeres asesinadas a partir de la reconstrucción de su memoria. Una memoria que las dignifique y reconozca como personas que tenían proyectos, triunfos, anhelos y sueños.' />

            <meta
              property="og:url"
              content="http://nosvanaverjuntas.org/favicon.ico/favicon-16x16.png"
            />
            <meta name="twitter:card" content='CASOS ACOMPAÑADOS POR OCNF' />

            <meta property="og:site_name" content='CASOS ACOMPAÑADOS POR OCNF' />
            <meta name="twitter:image:alt" content='CASOS ACOMPAÑADOS POR OCNF' />

            <meta property="fb:app_id" content="ocnfeminicidio.mexico" />
            <meta name="twitter:site" content="@OCNF" />
        </Helmet>

      <div className="block page cases">
        <div className="shape-container hero-map-conatiner">
          <div className="shape-row">
            <div className="shape-col-1" />
            <div className="shape-col-10">
              <div className="shape-map-header">
                <div className="shape-map-header-level1">
                  MAPA DE FEMINICIDIO Y TENTATIVAS
                </div>
                <div className="shape-map-header-level2">
                  DE FEMINICIDIO ACOMPAÑADOS POR OCNF
                </div>
              </div>
              <div className="shape-map-header-cta">
                Para filtrar los casos por Estado, haz click.
              </div>
            </div>
            <div className="shape-col-1" />
          </div>
          <div className="shape-row">
            <div className="shape-col-12">
              <Map />
            </div>
          </div>
        </div>
        <div className="shape-full-with-block">
          <div className="shape-container">
            <div className="shape-row">
              <div className="shape-col-12">
                <div className="tiny-text-note">
                  *Casos de Feminicidio acompañados por el Observatorio
                  Ciudadano Nacional de Feminicidio.
                </div>
                <Hero
                  cols={12}
                  tinyTitle="CASOS ACOMPAÑADOS POR OCNF"
                  title={[
                    {
                      text: selectedTipoDeCasoObj
                        ? selectedTipoDeCasoObj.label
                        : '',
                      color: selectedTipoDeCasoObj?.colorName || 'violet',
                    },
                  ]}
                />
                <BodyText
                  cols={12}
                  className="quote"
                  text={[
                    {
                      type: 'mark',
                      color: selectedTipoDeCasoObj?.colorName || 'violet',
                      text:
                        'Es imprescindible reivindicar el valor de las mujeres asesinadas a partir de la reconstrucción de su memoria. ',
                    },
                    {
                      type: 'p',
                      text:
                        'Una memoria que las dignifique y reconozca como personas que tenían proyectos, triunfos, anhelos y sueños.',
                    },
                  ]}
                />

                {casosForList && casosForList.length
                  ? casosForList.map((caso, key) => {
                    
                      return (
                        <div key={key} className="shape-row">
                          <Hero
                            cols={12}
                            tinyTitle="CASOS ACOMPAÑADOS POR OCNF"
                            title={[
                              {
                                text: caso.estado || '',
                                color: selectedTipoDeCasoObj?.colorName,
                              },
                            ]}
                          />

                          <div className="shape-row shape-victim-cards-container">
                            {caso.list.map((c, k) => (
                              <div
                                key={k + '-card'}
                                className="shape-col-4-np  shape-col-6-md-np  shape-col-12-sm-np"
                              >
                                <VictimCard
                                  {...c}
                                  ubicacion={caso.estado || ''}
                                ></VictimCard>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
}

const mapFormValueSelector = formValueSelector('mapForm')

const tipoDeCasoColors = [
  'rgba(192, 79, 181, 1)',
  'rgba(255, 179, 113, 1)',
  'rgba(109, 74, 255, 1)',
]

const tipoDeCasoColorNames = [
  'violet',
  'orange',
  'purple',
]

const mapStateToProps = (state: AppState) => {
  const tiposDeCasos = state.tiposDeCasos.items
  const selectedTipoDeCaso = mapFormValueSelector(state, 'tipoDeCaso')
  const selectedEstadoSelect = mapFormValueSelector(state, 'estados')

  const selectedEstadoMapIds =
    selectedEstadoSelect && selectedEstadoSelect.length
      ? selectedEstadoSelect.map(estado => 'MX.' + estado.value)
      : []

  const tiposDeCasosOptions = orderBy(tiposDeCasos, ['order'], [ 'asc', 'desc']).map(
    (tipo, key) => ({
      id: key,
      label: tipo.nombre,
      value: tipo.slug,
      color: tipoDeCasoColors[key],
      colorName:tipoDeCasoColorNames[key]
    })
  )

  const selectedTipoDeCasoObj = tiposDeCasosOptions.find(
    t => t.value === selectedTipoDeCaso
  )

  const casos = tipo => {
    switch (tipo) {
      case 'feminicidio':
        return selectedEstadoMapIds.length ?  state.feminicidios.items.filter(caso => selectedEstadoMapIds.includes(caso.feminicidio.HASC)) : state.feminicidios.items
      case 'tentativas-de-feminicidio':
        return selectedEstadoMapIds.length ?state.tentativas.items.filter(caso => selectedEstadoMapIds.includes(caso.feminicidio.HASC)): state.tentativas.items
      case 'otros-casos-de-violencia':
        return selectedEstadoMapIds.length ?state.otrosCasos.items.filter(caso => selectedEstadoMapIds.includes(caso.feminicidio.HASC)): state.otrosCasos.items
      default:
        return selectedEstadoMapIds.length ?state.casos.items.filter(caso => selectedEstadoMapIds.includes(caso.feminicidio.HASC)): state.casos.items || []
    }
  }

  const casosFormListFormat = casos(selectedTipoDeCaso).map(caso => ({
    ...caso,
    fecha: caso.feminicidio.fecha,
    ubicacion: caso.feminicidio.ubicacion,
    type: selectedTipoDeCaso,
  }))

  const casosForListGroupedby = groupBy(casosFormListFormat, 'ubicacion')

  const casosForList = Object.entries(casosForListGroupedby).map(c => ({
    estado: c[0],
    list: orderBy(c[1],'nombre'),
  }))

  return {
    tiposDeCasos,
    selectedTipoDeCaso,
    selectedTipoDeCasoObj,
    isFetchingCasos: state.casos.isFetching,

    casosForList,
  }
}

export default connect(mapStateToProps)(Cases)
