import React, { PureComponent } from 'react'

import { WrappedFieldProps } from 'redux-form'

import Map from './map'
import { LOCATIONS } from '../../constants'

type Props = {
  data: {
    HASC: string
    color: string
  }[]
} & React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps

class renderMapField extends PureComponent<Props> {
  handleSelect = id => {
    const {
      data,
      input: { value, onChange },
    } = this.props

    const dataId = data.map(d => d.HASC)
    const valuesIds = value ? value.map(v => 'MX.' + v.value) : []
    const formatedValue = LOCATIONS.find(
      location => 'MX.' + location.value === id
    )
    // @ts-ignore

    const arrayValues = value ? [...value, formatedValue] : [formatedValue]

    if (value && dataId.includes(id) && valuesIds.includes(id)) {
      const filteredList = arrayValues.filter(
        v => v.value !== id.replace('MX.', '')
      )

      onChange(filteredList)
    } else if (dataId.includes(id)) {
      onChange(arrayValues)
    }
  }

  render() {
    const { data } = this.props

    return (
      <>
        {data ? (
          <Map mapData={data} selectCountry={id => this.handleSelect(id)} />
        ) : null}
      </>
    )
  }
}

export default renderMapField
