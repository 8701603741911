import React, { Component } from 'react'
import { round } from 'lodash'

type Props = {}

class ScrollText extends Component<Props> {
  private scrollRef?: HTMLDivElement

  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll)
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.scrollY
    const itemTranslate = round(scrollTop)

    if (this.scrollRef) {
      this.scrollRef.scrollTo({ left: itemTranslate })
    }
  }

  render() {
    return (
      <div
        className="shape-scroll-container"
        ref={(node: HTMLDivElement) => (this.scrollRef = node)}
      >
        <div className="shape-scroll-text">
          Juntas, transformamos el dolor en esperanza. Ni una más, ni una menos.
          Juntas, transformamos el dolor en esperanza. Ni una más, ni una menos.
        </div>
      </div>
    )
  }
}

export default ScrollText
