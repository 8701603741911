import React, { PureComponent } from 'react'
import BodyText from '../BodyText'
import ReactPlayer from 'react-player'

import './index.css'
import Modal from '../../Modal'

type Props = {
  text: {
    type: string
    text: string
    color?: string
  }[]
} & any

type State = {
  openModal: boolean
}

class Video extends PureComponent<Props, State> {
  state = {
    openModal: false,
  }
  render() {
    const {
      cols,
      parent,
      level,
      videoTinyTitle,
      text,
      video,
      videoType,
      videoTitle,
    } = this.props

    const { openModal } = this.state

    return (
      <div className={`shape-col-${cols} shape-video level-${parent}-${level}`}>
        <div className="shape-row">
          <BodyText cols={7} level={1} parent={1} text={text} />
          <div className="shape-col-5 shape-col-12-sm shape-video-right">
            <div className="shape-video-right-container">
              <div className="shape-video-right-middle">
                <div className="shape-video-tiny-title">{videoTinyTitle}</div>
                <div className="shape-video-type">{videoType}</div>
                <div className="shape-video-title">{videoTitle}</div>
                <div
                  className="video-button"
                  onClick={() => this.setState({ openModal: true })}
                >
                  <div className="arrow"></div>
                </div>
              </div>
            </div>
          </div>

          {openModal ? (
            <Modal onRequestClose={() => this.setState({ openModal: false })}>
              <ReactPlayer className="react-player" url={video} width="100%" />
            </Modal>
          ) : null}
        </div>
      </div>
    )
  }
}

export default Video
