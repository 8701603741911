export const DATA = [
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 12,
        data: [
          {
            type: 'hero',
            tinyTitle: 'DE VÍCTIMAS A DEFENSORAS',
            title: [{ text: 'INTRODUCCIÓN' }],
            cols: 12,
          },
          {
            type: 'text',
            className: 'quote',
            text: [
              {
                type: 'mark',
                color: 'violet',
                text:
                  'Este apartado está dedicado a cada una de las mujeres, personas y familias que se convierten en personas defensoras de los derechos humanos y que no solo luchan por un caso, sino que con su voz cobijan a decenas de voces que, por miedo o falta de recursos, se mantienen en el anonimato.',
              },
            ],
            cols: 12,
          },
        ],
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
        data: [],
      },
      {
        type: 'col',
        cols: 10,
        data: [
          {
            type: 'text',
            text: [
              {
                type: 'p',
                text:
                  'La incansable labor de las madres, familiares de víctimas de feminicidio y sobrevivientes de violencia feminicida en busca de justicia, es el motor que guía y acompaña nuestro quehacer como defensoras de los derechos humanos.',
              },
            ],
            cols: 12,
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
        data: [],
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
        data: [],
      },
      {
        type: 'col',
        cols: 10,
        data: [
          {
            type: 'text',
            text: [
              {
                type: 'p',
                text:
                  'Como Católicas por el Derecho a Decidir A.C. y como Observatorio Ciudadano Nacional del Feminicidio (OCNF) hemos atestiguado cómo las víctimas transforman el dolor en una lucha que día a día contribuye a erradicar la violencia contra las mujeres y niñas en México.',
              },
            ],
            cols: 12,
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
        data: [],
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
        data: [],
      },
      {
        type: 'col',
        cols: 10,
        data: [
          {
            type: 'text',
            text: [
              {
                type: 'p',
                text:
                  'No todas las mujeres y familias pueden alzar la voz para romper el muro de la impunidad, pero quienes lo logran, se convierten en referentes en el ejercicio y la exigencia del derecho al acceso a la justicia, la verdad, la reparación integral del daño y de garantías de no repetición.',
              },
            ],
            cols: 12,
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
        data: [],
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
        data: [],
      },
      {
        type: 'col',
        cols: 10,
        data: [
          {
            type: 'text',
            className: 'quote',
            text: [
              {
                type: 'p',
                text:
                  'Sin duda, la labor de las madres, familias y sobrevivientes es fundamental para alcanzar la mano de la justicia. ',
              },
              {
                type: 'strong',
                text: 'A ellas y ellos, nuestro reconocimiento y admiración.',
              },
            ],
            cols: 12,
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
        data: [],
      },
    ],
  },
]
