import { IconProp } from '@fortawesome/fontawesome-svg-core'

import React, { PureComponent } from 'react'
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { round } from 'lodash'

import './index.css'

type Props = {
  url: string
}

class SoundCloudFrame extends PureComponent<Props> {
  state = {
    playing: false,
    isReady: false,
    duration: 0,
    progressPlayed: 0,
    loaded: 0,
  }
  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }

  handleReady = e => {
    this.setState({ isReady: e.player.isReady })
  }

  getOfPercentProgress = currValue => {
    const { duration } = this.state
    return (currValue / duration) * 100
  }

  render() {
    const { playing, duration, progressPlayed, loaded } = this.state
    const { url } = this.props
    return (
      <div className="react-player-soundcloud-wrapper">
        <div className="react-player-soundcloud">
          <ReactPlayer
            className="react-player"
            url={url}
            width="100%"
            height="120px"
            onReady={this.handleReady}
            onDuration={duration => this.setState({ duration })}
            onProgress={prodress =>
              this.setState({
                progressPlayed: prodress.playedSeconds,
                loaded: prodress.loadedSeconds,
              })
            }
            playing={playing}
          />
        </div>
        <div className="react-player-container">
          <div
            onClick={this.handlePlayPause}
            className={`${playing ? 'playing' : 'stop'} playStopButton`}
          >
            {!playing ? (
              <FontAwesomeIcon icon={'play-circle' as IconProp} fixedWidth />
            ) : (
              <div className="play">
                <FontAwesomeIcon icon={'volume-up' as IconProp} fixedWidth />
              </div>
            )}
          </div>

          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: this.getOfPercentProgress(progressPlayed) + '%' }}
            ></div>
            <div
              className="loaded"
              style={{ width: this.getOfPercentProgress(loaded) + '%' }}
            ></div>
          </div>
          <div className="durationVsProgres">
            {' '}
            <span className="text-color-dark-gray">{`${round(
              progressPlayed / 60,
              2
            ).toFixed(2)}`}</span>
            {`/${round(duration / 60, 2).toFixed(2)}`}
          </div>
        </div>
      </div>
    )
  }
}

export default SoundCloudFrame
