import { buffers } from 'redux-saga'
import { actionChannel, take, put, call, flush } from 'redux-saga/effects'

export function createRefreshTokenSaga(api) {
  return function*() {
    const buffer = buffers.expanding(50)
    const refreshTokenAction = yield actionChannel('REFRESH_TOKEN', buffer)

    while (true) {
      yield take(refreshTokenAction)
      try {
        yield put({ type: 'REFRESH_TOKEN_REQUEST' })
        const authed = yield call(api.auth.authenticate)
        yield put({ type: `REFRESH_TOKEN_${authed ? 'SUCCESS' : 'FAILURE'}` })
      } catch (error) {
        yield put({ type: 'REFRESH_TOKEN_FAILURE' })
      } finally {
        yield flush(refreshTokenAction)
      }
    }
  }
}
