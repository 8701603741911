import { FormErrors } from 'redux-form'

const validate = (values: {
  nombre: string
  correo: string
  asunto: string
  mensaje: string
}) => {
  const errors: FormErrors<{
    nombre: string
    correo: string
    asunto: string
    mensaje: string
  }> = {}

  if (!values.nombre || !values.asunto) {
    errors.nombre = '* Este campo es obligatorio'
  }

  if (!values.asunto) {
    errors.asunto = '* Este campo es obligatorio'
  }

  if (!values.correo) {
    errors.correo = '* Este campo es obligatorio'
  }

  if (!values.mensaje) {
    errors.mensaje = '* Este campo es obligatorio'
  }

  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  if (values.correo && !values.correo.match(mailformat)) {
    errors.correo = '* Correo no válido'
  }
  return errors
}

export default validate
