import React, { PureComponent } from 'react'
import MetaTags from 'react-meta-tags'

import { Location } from 'react-router-dom'

import { campaign1 } from '../../Images'

import { DATA } from './constants'

import './index.css'
import { Template } from '../../Components'

type Props = {
  location: Location
}

class Campaign extends PureComponent<Props> {
  render() {
    return (
      <>
        <MetaTags>
          <title>CASOS ACOMPAÑADOS POR OCNF</title>

          <meta property="og:title" content="#NOSVANAVERJUNTAS" />
          <meta
            property="og:description"
            content="En México, cada día son asesinadas 10 mujeres."
          />

          <meta property="og:url" content={campaign1} />
          <meta name="twitter:card" content="#NOSVANAVERJUNTAS" />

          <meta property="og:site_name" content="#NOSVANAVERJUNTAS" />
          <meta name="twitter:image:alt" content="#NOSVANAVERJUNTAS" />

          <meta property="fb:app_id" content="ocnfeminicidio.mexico" />
          <meta name="twitter:site" content="@OCNF" />
        </MetaTags>

        <div className="block page campaign">
          <div className="shape-container">
            <div className="shape-row">
              <div className="shape-col-1" />
              <div className="shape-col-10">
                <img src={campaign1} alt="nos van a ver juntas" />
              </div>
              <div className="shape-col-1" />
            </div>
          </div>
          <div className="shape-full-with-block">
            <div className="shape-container">
              <Template data={DATA} />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Campaign
