import React, { PureComponent, Fragment } from 'react'
import { Helmet } from 'react-helmet'

import { connect } from 'react-redux'

import { AppState, casoActions } from '../../state'
import { Link, RouterHistory, Match, Location } from 'react-router-dom'

import { ImageWithPlaceHolder, BodyText, Hero } from '../../Components'

import './index.css'
import { url } from '../../utils'
import { URLS, TIPOS_DE_CASO_OPTIONS } from '../../constants'

type OwnProps = {
  history: RouterHistory
  match: Match
  location: Location
}

type StateProps = ReturnType<typeof mapStateToProps>
type ActionsProps = typeof mapActionsToProps

type Props = StateProps & OwnProps & ActionsProps

class Case extends PureComponent<Props> {
  componentDidMount() {
    const { fetchCaso, slugCaso, slugTipo } = this.props
    fetchCaso({ slugTipo, slugCaso })
  }

  render() {
    const { caso, relacion, defensora, defensoraSlug, slugTipo } = this.props

    const name = caso ? caso.nombre.split(' ') : null

    const tipoDeCaso = TIPOS_DE_CASO_OPTIONS.find(t => t.slug === slugTipo)

    const color = tipoDeCaso?.color || 'violet'

    const age = caso
      ? caso.feminicidio.fecha.diff(caso.victima.fecha, 'years', true)
      : null

    const fixedAge = age ? age.toString().split('.') : null

    const materialCaso =
      caso && caso.material
        ? caso.material
            .map(material => {
              const linkOrVideo = material.url.includes('youtube' || 'vimeo')

              const text = [
                {
                  type: 'p',
                  text: material.origen,
                  color: 'gray-light',
                },
                {
                  type: 'strong',
                  text: material.titulo,
                  color: 'dark-purple',
                },
                {
                  type: linkOrVideo ? 'video' : 'link',
                  link: material.url,
                  text: 'Ver Noticia',
                  color: color,
                },
                {
                  type: 'hr',
                },
              ]

              return text
            })
            .flat(2)
        : []

    return (
      <>
        {caso ? (
          <Helmet>
            <title>{caso.nombre}</title>
            <meta name="title" content={caso.nombre} />
            <meta name="description" content={caso.intro} />

            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={`https://nosvanaverjuntas.org/caso/${slugTipo}/${caso.slug}`}
            />
            <meta property="og:title" content={caso.nombre} />
            <meta property="og:description" content={caso.intro} />
            <meta property="og:image" content={caso.foto} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:url"
              content={`https://nosvanaverjuntas.org/caso/${slugTipo}/${caso.slug}`}
            />
            <meta property="twitter:title" content={caso.nombre} />
            <meta property="twitter:description" content={caso.intro} />
            <meta property="twitter:image" content={caso.foto} />
          </Helmet>
        ) : null}

        <div className="block page case">
          {caso ? (
            <>
              <div className="shape-hero-image-fullImage">
                <ImageWithPlaceHolder alt={`${caso.slug}`} src={caso.foto} />
              </div>
              <div className="shape-text-hero first">
                <div className="shape-text-hero-title">
                  {name
                    ? name.map((n, k) => (
                        <Fragment key={k + 1}>
                          <span
                            className={
                              k === 0
                                ? 'text-color-' + color
                                : 'text-color-dark-purple'
                            }
                          >
                            {n}
                          </span>
                          {k === 0 ? <br /> : null}
                        </Fragment>
                      ))
                    : null}
                </div>
                <div className="age">
                  {fixedAge ? `Edad. ${fixedAge[0]} años` : ''}
                </div>
              </div>
            </>
          ) : (
            <>no existe caso</>
          )}
          {caso ? (
            <div className="shape-dates-info">
              <div className="left">
                <div className="title">FECHA Y LUGAR DE NACIMIENTO</div>
                <div className="date">
                  {`${caso.victima.fecha
                    .format('DD.MMMYYYY')
                    .toUpperCase()} / ${caso.victima.ubicacion}`}
                </div>
              </div>
              <div className="right">
                <div className="title">FECHA Y LUGAR DE FEMINICIDIO</div>
                <div className="date">
                  {' '}
                  {`${caso.feminicidio.fecha
                    .format('DD.MMMYYYY')
                    .toUpperCase()} / ${caso.feminicidio.ubicacion}`}
                </div>
              </div>
            </div>
          ) : null}
          <div className="shape-container">
            <div className="shape-row">
              {caso ? (
                <>
                  <div className="shape-col-1" />
                  <div className="shape-col-10-np ">
                    <div className=" intro">
                      <p>
                        <span className={`text-color-${color}`}>
                          <strong>{caso.nombre}</strong>
                        </span>
                        {caso.intro}
                      </p>
                    </div>

                    {caso.quote && caso.quote.text ? (
                      <BodyText
                        className={'quote'}
                        cols={12}
                        text={caso.quote.text}
                        level={0}
                        parent={0}
                      />
                    ) : null}

                    {relacion ? (
                      <div className="defensora-link">
                        <div className="defensora">
                          {tipoDeCaso?.id === 0
                            ? `${relacion} de ${name ? name[0] : ''}`
                            : null}
                        </div>
                        <Link
                          className={`link text-color-${color}`}
                          to={url(URLS.DEFENDER, defensoraSlug)}
                        >
                          {tipoDeCaso?.id === 0
                            ? defensora
                            : `${name ? name[0] : ''} como Defensora`}
                        </Link>
                      </div>
                    ) : null}

                    <div className="vertical-separator"></div>

                    {caso && caso.historia && caso.historia.length
                      ? caso.historia.map((block, key) => (
                          <BodyText
                            key={key}
                            cols={12}
                            text={block.text}
                            level={key}
                            parent={0}
                          />
                        ))
                      : null}

                    {caso.material.length && name ? (
                      <>
                        <Hero
                          tinyTitle={`CASO ${name[0].toUpperCase()}`}
                          level={3}
                          parent={3}
                          cols={12}
                          title={[
                            {
                              text: 'MATERIAL HEMEROGRÁFICO',
                              color: 'dark-purple',
                            },
                          ]}
                          subtitle={[
                            {
                              text:
                                'NOTAS PERIODÍSTICAS, ENTREVISTAS Y REPORTAJES',
                              color: color,
                            },
                          ]}
                        />

                        <div className="material-card">
                          <div className="shape-row">
                            <div className="shape-col-1" />
                            {materialCaso.length ? (
                              <BodyText
                                cols={10}
                                text={materialCaso}
                                level={0}
                                parent={0}
                              />
                            ) : null}
                            <div className="shape-col-1" />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="shape-col-1" />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const { params } = ownProps.match

  const slugTipo = params.typeSlug
  const caso = state.caso.items.find(caso => caso.slug === params.caseSlug)

  const color =
    TIPOS_DE_CASO_OPTIONS.find(t => t.slug === slugTipo)?.color || 'violet'

  const formattedQuote =
    caso && caso.descripcion
      ? {
          text: caso.descripcion
            .replace(/#1#/g, '--split--<MARK>')
            .replace(/#2#/g, '--split--')
            .split('--split--')
            .map(markText => {
              if (markText.includes('<MARK>')) {
                return {
                  type: 'mark',
                  color: color,
                  text: markText.replace(/<MARK>/g, ''),
                }
              } else {
                return {
                  type: 'p',
                  text: markText,
                }
              }
            }),
        }
      : null

  return {
    relacion:
      caso && caso.defensores_id ? caso.defensores_id.tipo_familiar : '',
    defensora: caso && caso.defensores_id ? caso.defensores_id.nombre : '',
    defensoraSlug: caso && caso.defensores_id ? caso.defensores_id.slug : '',
    slugTipo,
    slugCaso: params.caseSlug,
    caso: caso
      ? {
          ...caso,
          quote: formattedQuote,

          historia:
            caso &&
            caso.historia.map(h => {
              if (
                h.data.text &&
                typeof h.data.text === 'string' &&
                h.data.text.includes('</b>')
              ) {
                return {
                  text: h.data.text
                  .replace(/&nbsp;/g, '')
                    .replace(/<b>/g, '--split--<BOLD>')
                    .replace(/\//g, '-')
                    .replace(/<-b>/g, '--split--')
                    .split('--split--')
                    .map(boldText => {
                      if (boldText.includes('<BOLD>')) {
                        return {
                          type: 'strong',
                          text: boldText.replace(/<BOLD>/g, ''),
                        }
                      } else {
                        return {
                          type: 'p',
                          text: boldText,
                        }
                      }
                    }),
                }
              }
              if (h.data.text && typeof h.data.text === 'string') {
                return {
                  text: [
                    {
                      type: 'p',
                      text: h.data.text
                        ? h.data.text
                            .replace(/&nbsp;/g, '')
                            .replace(/<mark class="cdx-marker">/g, '')
                            .replace(/\//g, '-')
                            .replace(/<-mark>/g, '')
                        : '',
                    },
                  ],
                }
              }
              if (!h.data.text && h.data.items.length) {
                return {
                  text: [
                    {
                      type: 'ol',
                      items: h.data.items.map(i => i.replace(/&nbsp;/g, '')),
                    },
                  ],
                }
              }
              return null
            }),
        }
      : null,
    casos: state.casos.items,
    isFetching: state.casos.isFetching || state.caso.isFetching,
  }
}

const mapActionsToProps = {
  fetchCaso: casoActions.fetchResources,
}

export default connect(mapStateToProps, mapActionsToProps)(Case)
