import {
  campaign2,
  campaign3,
  campaign4,
  campaign5,
  campaign6,
  campaign7,
  campaignLogoPartner1,
  campaignLogoPartner5,
  campaignLogoPartner4,
} from '../../Images'

export const DATA = [
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 5,
        data: [
          {
            type: 'hero',
            tinyTitle: 'SOBRE LA CAMPAÑA',
            title: [{ text: 'INICIATIVA' }],
            cols: 12,
          },
          {
            type: 'dotText',
            title: 'En México, cada día son asesinadas 10 mujeres.',
            color: 'orange',
            cols: 12,
          },
          {
            type: 'text',
            text: [
              {
                type: 'p',
                text:
                  'Vivimos en un contexto grave de violencia contra las mujeres y de agudización del feminicidio, en donde las propias autoridades federales han declarado Alerta de Violencia de Género en 59% del territorio nacional.',
              },
            ],
            cols: 12,
          },
        ],
      },
      {
        type: 'col',
        cols: 7,
        data: [
          {
            type: 'img',
            img: campaign2,
            cols: 12,
          },
        ],
      },
    ],
  },

  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
        data: [],
      },
      {
        type: 'col',
        cols: 10,
        data: [
          {
            type: 'text',
            text: [
              {
                type: 'p',
                text:
                  'Los feminicidios están rodeados, además, de una falta de reconocimiento a la dignidad de las víctimas y sus familias, la cual se manifiesta a través de cuestionamientos, descalificaciones, falta de acceso a información y filtración de datos. ',
              },
            ],
            cols: 12,
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
        data: [],
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
        data: [],
      },
      {
        type: 'col',
        cols: 10,
        data: [
          {
            type: 'text',
            text: [
              {
                type: 'p',
                text:
                  'Muchos de los discursos o imágenes que se difunden en los medios de comunicación promueven la estigmatización de las mujeres y niñas que han sido asesinadas, fomentando la reproducción de estereotipos e incluso culpabilizándolas de su propia muerte. Esto genera una múltiple victimización, agresiones e impactos negativos en las emociones y salud de familias enteras.',
              },
            ],
            cols: 12,
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
        data: [],
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
        data: [],
      },
      
      {
        cols: 10,
        type: 'col',
        data: [
          {
            type: 'video',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  '"Vemos mucho enojo y rabia de las familias, de los movimientos feministas y las defensoras de derechos humanos. ',
              },
              {
                type: 'strong',
                text:
                  'Hay una indignación por no encontrar respuesta de las autoridades',
              },
              {
                type: 'p',
                text: ' y el daño no solamente ',
              },
              {
                type: 'strong',
                text:
                  ' afecta a las familias sino lastima el tejido social y a la sociedad"',
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                color: 'violet',
                text: 'Algunas de las palabras de',
              },
              {
                type: 'strong',
                color: 'violet',
                text: ' María de la Luz Estrada, Coordinadora del OCNF',
              },
              {
                type: 'p',
                color: 'violet',
                text: ' en dicha presentación.',
              },
            ],

            videoTinyTitle: 'Ver Capítulo 1',
            videoType: 'CORTOMETRAJE',
            videoTitle: 'INDIGNACIÓN',
            video: 'https://www.youtube.com/watch?v=v4HZNCdD4qI',
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
        data: [],
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        data: [
          {
            type: 'text',
            className: 'quote',
            text: [
              {
                type: 'p',
                text: 'En consecuencia',
              },
              {
                type: 'mark',
                color: 'orange',
                text:
                  ' ser madre o familiar de una víctima de feminicidio, significa entregarse a una lucha desgastante y dolorosa que usualmente no encuentra justicia ni reparación.',
              },
            ],
            cols: 12,
          },
        ],
        cols: 10,
      },
      {
        type: 'col',
        cols: 1,
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        data: [
          {
            type: 'text',
            cols: 12,
            text: [
              { type: 'strong', text: '#NOSVANAVERJUNTAS ' },
              {
                type: 'p',
                text:
                  'es una campaña de Católicas por el Derecho a Decidir (CDD) y el Observatorio Ciudadano Nacional del Feminicidio (OCNF) que surge porque es imprescindible reivindicar el valor de las mujeres asesinadas a partir de la reconstrucción de su memoria. Una memoria que las dignifique y reconozca como personas que tenían proyectos, triunfos, anhelos y sueños, y que nos haga ver que todas las ausencias son importantes y no deberían seguir sucediendo. ',
              },
            ],
          },
        ],
        cols: 10,
      },
      {
        type: 'col',
        cols: 1,
      },
    ],
  },

  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        data: [
          {
            type: 'text',
            cols: 12,
            text: [
              { type: 'strong', text: '#NOSVANAVERJUNTAS ' },
              {
                type: 'p',
                text:
                  'nace también porque es fundamental reconocer la labor incansable de sus familias, particularmente de las madres, quienes acompañadas de organizaciones de la sociedad civil y defensoras de los derechos humanos, contribuyen a transformar sus realidades y se convierten en el motor y vehículo hacia la verdad y la justicia.',
              },
            ],
          },
        ],
        cols: 10,
      },
      {
        type: 'col',
        cols: 1,
      },
    ],
  },

  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        data: [
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'Muchas de ellas son transgresoras porque pasan de ser víctimas a ser defensoras de derechos humanos y se convierten en un rayo de luz para otras. Juntas toman fuerza, se acompañan y transforman el dolor en esperanza.',
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text: 'La campaña busca',
              },
              {
                type: 'strong',
                text:
                  ' ser un espacio para sumar a más personas en este camino hacia la justicia; para que formemos parte de esta comunidad que visibiliza y contribuye a erradicar la violencia feminicida en México.',
              },
            ],
          },
        ],
        cols: 10,
      },
      {
        type: 'col',
        cols: 1,
      },
    ],
  },

  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        data: [
          {
            type: 'text',
            className: 'quote',
            text: [
              {
                type: 'p',
                text: 'Por ellas y por todas #NOSVANAVERJUNTAS ',
              },
              {
                type: 'br',
              },
              {
                type: 'mark',
                color: 'orange',
                text: '¡te invitamos a ser parte de este cambio! ',
              },
            ],
            cols: 12,
          },
        ],
        cols: 10,
      },
      {
        type: 'col',
        cols: 1,
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        cols: 10,
        data: [
          {
            type: 'img',
            img: campaign3,
            cols: 12,
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
      },
    ],
  },

  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 12,
        data: [
          {
            type: 'hero',
            tinyTitle: 'SOBRE LA CAMPAÑA',
            title: [
              {
                text: 'PRESENTACIÓN DE LOS CORTOMETRAJES',
                color: 'dark-purple',
              },
            ],
            subtitle: [
              { text: 'INDIGNACIÓN, MEMORIA Y ESPERANZA', color: 'violet' },
            ],
            cols: 12,
          },
          {
            type: 'text',
            cols: 12,
            className: 'quote',
            text: [
              {
                type: 'p',
                text:
                  'Los cortometrajes Indignación y Memoria fueron presentados en el marco del ',
              },
              {
                type: 'mark',
                color: 'violet',
                text:
                  '"Día Internacional de las Defensoras de Derechos Humanos", el 28 de noviembre de 2019',
              },
              {
                type: 'p',
                text:
                  ' en el Museo Memoria y Tolerancia de la Ciudad de México.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        data: [
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'En dicha presentación algunas de las madres de los casos acompañados por el Observatorio Ciudadano Nacional del Feminicidio, compartieron su testimonio y el peregrinar que les ha implicado buscar el acceso a la justicia para sus hijas, reivindicaron también la importancia de poder estar juntas y acompañadas por otras madres, organizaciones de la sociedad civil y defensoras de los derechos humanos. ',
              },
            ],
          },
        ],
        cols: 10,
      },
      {
        type: 'col',
        cols: 1,
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
        data: [],
      },
      {
        cols: 10,
        type: 'col',
        data: [
          {
            type: 'video',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'Ser víctima de feminicidio en nuestro país significa no sólo perder la vida sino la identidad, para convertirse en un expediente más entre miles.',
              },
            ],

            videoTinyTitle: 'Ver Capítulo 2',
            videoType: 'CORTOMETRAJE',
            videoTitle: 'MEMORIA',
            video: 'https://youtu.be/IdvXtb73Aig',
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
        data: [],
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        cols: 10,
        data: [
          {
            type: 'img',
            img: campaign4,
            cols: 12,
            figcaption:
              'Presentación de los cortometrajes Indignación y Memoria, 28 de noviembre de 2019. Museo Memoria y Tolerancia, Ciudad de México.',
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
      },
    ],
  },

  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
        data: [],
      },
      {
        cols: 10,
        type: 'col',
        data: [
          {
            type: 'video',
            cols: 12,
            text: [
              {
                type: 'p',
                text: '“Porque ',
              },
              {
                type: 'strong',
                text:
                  'no queremos ni una niña, ni una mujer más, víctima de feminicidio, ni de violencia”.',
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                color: 'violet',
                text: 'Manifestó su respaldo',
              },
              {
                type: 'strong',
                text:
                  ' Nira Cárdenas, Oficial de Derechos Humanos de la Oficina del Alto Comisionado de las Naciones Unidas para los Derechos Humanos en México.',
              },
            ],

            videoTinyTitle: 'Ver Capítulo 3',
            videoType: 'CORTOMETRAJE',
            videoTitle: 'ESPERANZA',
            video: 'https://youtu.be/xeSWxaWHjWQ',
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
        data: [],
      },
    ],
  },

  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 12,
        data: [
          {
            type: 'hero',
            tinyTitle: 'SOBRE LA CAMPAÑA',
            title: [{ text: 'LANZAMIENTO DE CAMPAÑA', color: 'dark-purple' }],
            cols: 8,
          },
          {
            type: 'img',
            img: campaign5,
            cols: 12,
            figcaption:
              'Lanzamiento de la campaña #NOSVANAVERJUNTAS, 5 de marzo 2020. Ciudad de México.',
          },
          {
            type: 'dotText',
            title:
              'El lanzamiento de la campaña #NOSVANAVERJUNTAS se realizó el 5 de marzo de 2020 en la Ciudad de México, en el marco del Día Internacional de las Mujeres.',
            color: 'orange',
            cols: 6,
          },
        ],
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        data: [
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'Inició con una conferencia de prensa en donde fueron presentados los tres cortometrajes que forman parte de la misma: Indignación, Memoria y Esperanza.',
              },
            ],
          },

          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'En el evento participaron, madres y padres de víctimas de desaparición y feminicidio, representantes de la Embajada de Países Bajos, de la Embajada de Australia, de la Oficina del Alto Comisionado de las Naciones Unidas para los Derechos Humanos en México, de la Comisión de Derechos Humanos de la Ciudad de México, así como defensoras de los derechos de las mujeres, integrantes de Católicas por el Derecho a Decidir A.C. y del Observatorio Ciudadano Nacional del Feminicidio, medios de comunicación y público en general.',
              },
            ],
          },
          {
            type: 'text',
            className: 'quote',
            cols: 12,
            text: [
              {
                type: 'p',
                text: 'En el evento, las madres de víctimas hicieron un ',
              },
              {
                type: 'mark',
                color: 'orange',
                text:
                  'llamado a la sociedad a unirse al clamor de justicia y a exigir a las autoridades el cese de la impunidad, así como una estrategia clara y efectiva para erradicar la violencia ',
              },
              {
                type: 'p',
                text: 'que cada día acaba con la vida de al menos 10 mujeres.',
              },
            ],
          },
          {
            type: 'img',
            img: campaign6,
            cols: 12,
          },
          {
            type: 'text',
            cols: 6,
            text: [
              {
                type: 'p',
                text:
                  'Los cortometrajes también fueron proyectados en el Foro al Aire Libre de la Cineteca Nacional en la Ciudad de México. En la audiencia participaron principalmente jóvenes y estudiantes. ',
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                className: 'figcaption',
                text:
                  'Proyección de los videos de la campaña #NOSVANAVERJUNTAS, 5 de marzo 2020, Cineteca Nacional, Ciudad de México.',
              },
            ],
          },
          {
            type: 'img',
            img: campaign7,
            cols: 6,
          },
          {
            type: 'col',
            cols: 12,
          },
          {
            type: 'text',
            className: 'quote',
            cols: 7,
            text: [
              {
                type: 'p',
                text: 'Dicha presentación también ',
              },
              {
                type: 'mark',
                color: 'orange',
                text:
                  'contó con la presencia de madres y padres de familia de víctimas de feminicidio, ',
              },
              {
                type: 'p',
                text:
                  'defensoras y defensores de los derechos humanos y organizaciones de la sociedad civil.',
              },
            ],
          },
        ],
        cols: 10,
      },
      {
        type: 'col',
        cols: 1,
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 12,
        data: [
          {
            type: 'hero',
            tinyTitle: 'SOBRE LA CAMPAÑA',
            title: [
              { text: 'COMUNICADOS', color: 'violet' },
              { text: 'DEL LANZAMIENTO DE LA CAMPAÑA', color: 'dark-purple' },
            ],

            cols: 12,
          },
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text: 'Frente a violencia feminicida: #NOSVANAVERJUNTAS: OCNF.',
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            text: [
              {
                className: 'link',
                type: 'link',
                link: 'https://bit.ly/2y7vpeH',
                text: 'Ver comunicado',
                color: 'violet',
              },
              {
                type: 'hr',
              },
            ],
          },
          {
            type: 'hero',
            tinyTitle: 'SOBRE LA CAMPAÑA',
            title: [
              { text: 'NOTICIAS', color: 'violet' },
              {
                text: 'SOBRE LOS CORTOMETRAJES Y EL LANZAMIENTO DE LA CAMPAÑA',
                color: 'dark-purple',
              },
            ],

            cols: 12,
          },
        ],
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
        data: [],
      },
      {
        cols: 10,
        type: 'col',
        data: [
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text: 'LA JORNADA',
                color: 'gray-light',
              },

              {
                type: 'strong',
                text:
                  'Acusan indiferencia de autoridades y sociedad por feminicidios',
                color: 'dark-purple',
              },

              {
                type: 'link',
                link:
                  'https://lajornadasanluis.com.mx/nacional/acusan-indiferencia-de-autoridades-y-sociedad-por-feminicidios/',
                text: 'Ver Noticia',
                color: 'violet',
              },
              {
                type: 'hr',
              },
              {
                type: 'p',
                text: 'REFORMA',
                color: 'gray-light',
              },

              {
                type: 'strong',
                text: 'Lanzan Campaña #NOSVANAVERJUNTAS',
                color: 'dark-purple',
              },

              {
                type: 'link',
                link:
                  'https://www.reforma.com/aplicacioneslibre/preacceso/articulo/default.aspx?__rval=1&urlredirect=https://www.reforma.com/lanzan-campana-nosvanaverjuntas/ar1889730?referer=--7d616165662f3a3a6262623b727a7a7279703b767a783a--',
                text: 'Ver Noticia',
                color: 'violet',
              },

              {
                type: 'hr',
              },
              {
                type: 'p',
                text: 'LA JORNADA',
                color: 'gray-light',
              },

              {
                type: 'strong',
                text:
                  'ONUDH: en México se cometen en promedio 10.5 feminicidios al día',
                color: 'dark-purple',
              },

              {
                type: 'link',
                link: 'https://www.jornada.com.mx/2020/03/06/politica/004n1pol',
                text: 'Ver Noticia',
                color: 'violet',
              },

              {
                type: 'hr',
              },
              {
                type: 'p',
                text: 'SDP NOTICIAS',
                color: 'gray-light',
              },

              {
                type: 'strong',
                text:
                  '"NOS VAN A VER JUNTAS" contra la violencia hacia las mujeres: OCNF y madres de víctimas',
                color: 'dark-purple',
              },
              {
                type: 'link',
                link:
                  'https://www.sdpnoticias.com/nacional/nos-van-a-ver-juntas-ocnf-feminicidios-paro-de-mujeres-9-de-marzo.html',
                text: 'Ver Noticia',
                color: 'violet',
              },

              {
                type: 'hr',
              },
              {
                type: 'p',
                text: 'PÁSALA',
                color: 'gray-light',
              },

              {
                type: 'strong',
                text: 'OCNF presenta la campaña #NOSVANAVERJUNTAS',
                color: 'dark-purple',
              },

              {
                type: 'link',
                link:
                  'https://www.pasala.com.mx/noticias/nacionales/ocnf-presenta-la-campana-nosvanaverjuntas',
                text: 'Ver Noticia',
                color: 'violet',
              },

              {
                type: 'hr',
              },
              {
                type: 'p',
                text: 'GOBERNANTES.COM',
                color: 'gray-light',
              },

              {
                type: 'strong',
                text:
                  'ONU: feminicidios en méxico crecieron diariamente de 7 a 10 en tres años.',
                color: 'dark-purple',
              },

              {
                type: 'link',
                link: 'http://www.gobernantes.com/vernota.php?id=282243',
                text: 'Ver Noticia',
                color: 'violet',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
        data: [],
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 12,
        data: [
          {
            type: 'hero',
            tinyTitle: 'SOBRE LA CAMPAÑA',
            title: [{ text: 'DONANTES', color: 'dark-purple' }],
            cols: 12,
          },
        ],
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 1,
        data: [],
      },
      {
        type: 'col',
        cols: 10,
        data: [
          {
            type: 'text',
            cols: 10,
            className: 'dotText',
            text: [
              {
                type: 'p',
                text:
                  'Este micrositio es una iniciativa de Católicas por el Derecho a Decidir, A.C. y el Observatorio Ciudadano Nacional del Feminicidio, realizada gracias al apoyo del Programa de Ayuda Directa (Australian Aid) del Gobierno de Australia y la Unión Europea. ',
              },
            ],
          },
          {
            type: 'text',
            cols: 12,

            text: [
              {
                type: 'p',
                text:
                  'Los cortometrajes de la campaña #NOSVANAVERJUNTAS fueron realizados con el apoyo de la Embajada del Reino de los Países Bajos en México.',
              },
            ],
          },
          {
            type: 'text',
            cols: 12,

            text: [
              {
                type: 'p',
                text:
                  'Todos los contenidos son responsabilidad exclusiva de Católicas por el Derecho a Decidir A.C. y el Observatorio Ciudadano Nacional del Feminicidio y no necesariamente corresponden con las opiniones de los financiadores.',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
        data: [],
      },
    ],
  },
  {
    type: 'row',
    data: [
      {
        type: 'col',
        cols: 12,
        data: [
          {
            type: 'img',
            img: campaignLogoPartner1,
            cols: 4,
          },

          {
            type: 'img',
            img: campaignLogoPartner5,
            cols: 4,
          },
          {
            type: 'img',
            img: campaignLogoPartner4,
            cols: 4,
          },
        ],
      },
    ],
  },
]
