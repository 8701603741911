import { takeLatest, takeEvery, call, put } from 'redux-saga/effects'
import snakeCase from 'lodash.snakecase'
import { singular } from 'pluralize'

export function createResourceSagas(api, resource, actions) {
  const RESOURCE = snakeCase(resource).toUpperCase()
  const Resource = resource.replace(/./, RESOURCE[0])
  const SingularResource = singular(Resource)

  return function*() {
    yield takeLatest(actions[`FETCH_${RESOURCE}`], function*(action) {
      if (!api[`fetch${Resource}`]) {
        throw new Error(`api.fetch${Resource} is not defined`)
      }

      yield put(actions[`fetch${Resource}Request`]())

      try {
        const response = yield call(api[`fetch${Resource}`], action)

        yield put(actions[`fetch${Resource}Success`](response))
      } catch (error) {
        yield put(actions[`fetch${Resource}Failure`](error))
      }
    })

    yield takeEvery(actions[`CREATE_${RESOURCE}`], function*(action) {
      if (!api[`create${SingularResource}`]) {
        throw new Error(`api.create${SingularResource} is not defined`)
      }

      yield put(actions[`create${Resource}Request`]())

      try {
        const item = yield call(api[`create${SingularResource}`], action.item)

        yield put(actions[`create${Resource}Success`](item))
      } catch (error) {
        yield put(actions[`create${Resource}Failure`](error))
      }
    })

    yield takeEvery(actions[`UPDATE_${RESOURCE}`], function*(action) {
      if (!api[`update${SingularResource}`]) {
        throw new Error(`api.update${SingularResource} is not defined`)
      }

      yield put(actions[`update${Resource}Request`]())

      try {
        const item = yield call(api[`update${SingularResource}`], action.item)

        yield put(actions[`update${Resource}Success`](item))
      } catch (error) {
        yield put(actions[`update${Resource}Failure`](error))
      }
    })

    yield takeEvery(actions[`DELETE_${RESOURCE}`], function*(action) {
      if (!api[`delete${SingularResource}`]) {
        throw new Error(`api.delete${SingularResource} is not defined`)
      }

      yield put(actions[`delete${Resource}Request`]())

      try {
        yield call(api[`delete${SingularResource}`], action.item)

        yield put(actions[`delete${Resource}Success`](action.item))
      } catch (error) {
        yield put(actions[`delete${Resource}Failure`](error))
      }
    })
  }
}
