import React, { PureComponent } from 'react'
import MetaTags from 'react-meta-tags'

import { connect } from 'react-redux'
import { RouterHistory, Match, Location, Route } from 'react-router-dom'
import { groupBy, orderBy } from 'lodash'

import { multimediaActions, AppState } from '../../state'

import { Template } from '../../Components'
import { URLS } from '../../constants'

import Video from './Video'
import PostalesListWrapper from './PostalesListWrapper'
import Postal from './Postal'
import Infografias from './Infografias'
import Infografia from './Infografia'

import './index.css'
import Videos from './Videos'

type OwnProps = {
  history: RouterHistory
  match: Match
  location: Location
}

type StateProps = ReturnType<typeof mapStateToProps>
type ActionsProps = typeof mapActionsToProps

type Props = StateProps & OwnProps & ActionsProps

const POSTALES_DATA = [
  {
    type: 'row',
    data: [
      { type: 'col', cols: 1 },
      {
        type: 'col',
        cols: 10,
        data: [
          {
            type: 'hero',
            tinyTitle: 'MULTIMEDIA',
            title: [{ text: 'POSTALES', color: 'purple' }],
            cols: 12,
          },
          {
            type: 'text',
            className: 'quote',
            text: [
              {
                type: 'mark',
                color: 'purple',
                text:
                  'Las madres y familiares de violencia feminicida junto con las defensoras de los derechos humanos que las acompañamos hemos tenido que encontrar formas creativas para visibilizar sus casos y exigir justicia',
              },
              {
                type: 'p',
                text:
                  ' ¡Únete y comparte, forma parte de la comunidad que se manifiesta en contra de la violencia contra las mujeres!',
              },
            ],
            cols: 12,
          },
        ],
      },
      { type: 'col', cols: 1 },
    ],
  },
]

const DATA_INFOGRAFIAS = [
  {
    type: 'row',
    data: [
      { type: 'col', cols: 1 },
      {
        type: 'col',
        cols: 10,
        data: [
          {
            type: 'hero',
            tinyTitle: 'MULTIMEDIA',
            title: [{ text: 'INFOGRAFÍAS', color: 'purple' }],
            cols: 12,
          },
          {
            type: 'text',
            className: 'quote',
            text: [
              {
                type: 'mark',
                color: 'purple',
                text:
                  'Las víctimas de feminicidio no son números ni estadística, son la realidad de la violencia que las mujeres enfrentan en México ',
              },
              {
                type: 'p',
                text:
                  '¡Difunde y alza la voz para visibilizar la violencia y contribuir a erradicar la violencia feminicida!',
              },
            ],
            cols: 12,
          },
        ],
      },
      { type: 'col', cols: 1 },
    ],
  },
]

class Multimedia extends PureComponent<Props> {
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.fetchMultimedia()
  }

  componentDidUpdate = prevProps => {
    const { location } = this.props

    const modalOpen = location.pathname.includes('/multimedia/')
    if (modalOpen) {
      let r = document.getElementById('body')
      r && r.classList.add('blockScroll')
    } else {
      let r = document.getElementById('body')
      r && r.classList.remove('blockScroll')
    }
  }

  render() {
    const { videos, postales, infografias } = this.props

    return (
      <>
        <MetaTags>
          <title>MULTIMEDIA</title>

          <meta property="og:title" content="MULTIMEDIA" />
          <meta
            property="og:description"
            content="Las madres y familiares de violencia feminicida junto con las defensoras de los derechos humanos que las acompañamos hemos tenido que encontrar formas creativas para visibilizar sus casos y exigir justicia."
          />

          <meta name="twitter:card" content="MULTIMEDIA" />

          <meta property="og:site_name" content="MULTIMEDIA" />
          <meta name="twitter:image:alt" content="MULTIMEDIA" />

          <meta property="fb:app_id" content="ocnfeminicidio.mexico" />
          <meta name="twitter:site" content="@OCNF" />
        </MetaTags>

        <div className="block page multimedia">
          <Videos videos={videos}></Videos>

          <div className="shape-full-with-block">
            <div className="shape-container">
              {postales.length ? (
                <>
                  <Template data={POSTALES_DATA} />

                  <div className="postales-by-serie">
                    {orderBy(postales, 'level').map(postal => {
                      return (
                        <PostalesListWrapper
                          key={postal.level}
                          level={postal.level}
                          serie={postal.serie}
                        />
                      )
                    })}
                  </div>
                </>
              ) : null}

              {infografias.length ? (
                <>
                  <Template data={DATA_INFOGRAFIAS} />
                  <Infografias infografias={infografias} />
                </>
              ) : null}
            </div>
          </div>
        </div>

        <Route
          exact
          path={URLS.MULTIMEDIA_VIDEO}
          component={Video}
          history={this.props.history}
        />
        <Route
          exact
          path={URLS.MULTIMEDIA_POSTAL}
          component={Postal}
          history={this.props.history}
        />

        <Route
          exact
          path={URLS.MULTIMEDIA_INFOGRAFIA}
          component={Infografia}
          history={this.props.history}
        />
      </>
    )
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const multimediaGroupedBy = groupBy(state.multimedia.items, 'tipo')

  const videos = multimediaGroupedBy.video
    ? orderBy(multimediaGroupedBy.video, 'titulo')
    : []
  const postales = multimediaGroupedBy.postal
    ? orderBy(multimediaGroupedBy.postal, 'titulo')
    : []
  const infografias = multimediaGroupedBy.infografia
    ? orderBy(multimediaGroupedBy.infografia, 'titulo')
    : []

  const postalesBySerie = postales.length ? groupBy(postales, 'serie') : null
  const postalesSeries = postalesBySerie
    ? Object.entries(postalesBySerie).map((serie, key) => {
        return {
          level:
            serie[0] === 'ESPERANZA'
              ? 3
              : serie[0] === 'INDIGNACIÓN'
              ? 1
              : serie[0] === 'MEMORIA'
              ? 2
              : key,
          serie: {
            name: serie[0],
            postales: serie[1],
          },
        }
      })
    : []

  return {
    videos,
    postales: postalesSeries,
    infografias,
  }
}

const mapActionsToProps = {
  fetchMultimedia: multimediaActions.fetchResources,
}

export default connect(mapStateToProps, mapActionsToProps)(Multimedia)
