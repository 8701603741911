import React, { PureComponent } from 'react'
type Props = {
  onPrevClick: () => void
  onNextClick: () => void
  goTopage: (number) => void
  pages: number[]
  activePage: number
}

class Controls extends PureComponent<Props> {
  render() {
    const { pages, activePage } = this.props
    return (
      <>
        <div className="buttons">
          <button
            className="button-left"
            onClick={() => this.props.onPrevClick()}
          ></button>
          <button
            className="button-right"
            onClick={() => this.props.onNextClick()}
          ></button>
        </div>
        {activePage ? (
          <div className="info">
            {pages
              .filter(p => p)
              .map(page => (
                <div
                  key={page}
                  className={`page ${activePage === page ? 'active' : ''} ${
                    activePage > page ? 'viewed' : ''
                  }`}
                  onClick={() => this.props.goTopage(page)}
                >
                  <div className="progress" />
                </div>
              ))}
          </div>
        ) : null}
      </>
    )
  }
}

export default Controls
