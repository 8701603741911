import React, { PureComponent } from 'react'
import MetaTags from 'react-meta-tags'

import { connect } from 'react-redux'
import { RouterHistory, Match } from 'react-router-dom'

import { Modal, Image } from '../../../Components'
import { URLS } from '../../../constants'
import { AppState } from '../../../state'

type StateProps = ReturnType<typeof mapStateToProps>

type OwnProps = {
  history: RouterHistory
  match: Match
}

type Props = OwnProps & StateProps

class Infografia extends PureComponent<Props> {
  render() {
    const { history, infografia } = this.props

    return (
      <>
        <MetaTags>
          <title>{infografia?.titulo}</title>

          <meta property="og:title" content={infografia?.titulo} />
          <meta
            property="og:description"
            content={infografia?.descripcion || 'Infografia'}
          />
          <meta property="og:url" content={infografia?.imagen || ''} />
          <meta name="twitter:card" content={infografia?.titulo} />

          <meta property="og:site_name" content={infografia?.titulo} />
          <meta name="twitter:image:alt" content={infografia?.titulo} />

          <meta property="fb:app_id" content="ocnfeminicidio.mexico" />
          <meta name="twitter:site" content="@OCNF" />
        </MetaTags>

        <div className="multimedia-video-modal">
          <Modal onRequestClose={() => history.push(URLS.MULTIMEDIA)}>
            <Image
              parent={0}
              level={0}
              cols={12}
              img={infografia?.imagen || ''}
              figcaption={infografia?.descripcion}
            />
          </Modal>
        </div>
      </>
    )
  }
}
const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const {
    params: { slug },
  } = ownProps.match

  return {
    infografia: state.multimedia.items.find(m => m.slug === slug),
  }
}

export default connect(mapStateToProps)(Infografia)
