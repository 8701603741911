import React, { PureComponent } from 'react'
import Col from './Col'

type Props = {
  data: any
  level: number
}

class Row extends PureComponent<Props> {
  render() {
    const { data, level } = this.props

    return (
      <div className={`shape-row  level-${level}`}>
        <>
          {data.map((d, key) => {
            if (d.type === 'col') {
              return <Col key={key} data={d.data} level={key} nc={d.cols} />
            }
            return null
          })}
        </>
      </div>
    )
  }
}

export default Row
