import { fork } from 'redux-saga/effects'

import { createResourceSagas } from './createResourceSagas'
import { createResourceActions } from './createResourceActions'
import { createRefreshTokenSaga } from './createRefreshTokenSaga'
import { monitorSagas } from './monitorSagas'

export function createResourcesSagas(
  sagaMiddleware,
  api,
  resources,
  externalSagas = []
) {
  const refreshTokenSaga = createRefreshTokenSaga(api)

  sagaMiddleware.run(function* startup() {
    yield fork(refreshTokenSaga)
    yield fork(monitorSagas)

    for (let resource of resources) {
      yield fork(
        createResourceSagas(api, resource, createResourceActions(resource))
      )
    }

    for (let externalSaga of externalSagas) {
      yield fork(externalSaga)
    }
  })

  return sagaMiddleware
}
